import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Routes } from '../../utilities/classes/routes.class';
import { LoginService } from '../../authentication/login/login-service/login.service';
import { Helper } from '../../utilities/classes/helper.class';
import { AccMessageDialogService } from '../../custom-components/acc-message-dialog/acc-message-dialog-service/acc-message-dialog.service';
import { combineLatest, filter, finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { LoaderService } from '../../utilities/services/loader/loader.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  public readonly routes = Routes;

  constructor(
    public loader: LoaderService,

    private _dialog: AccMessageDialogService,
    private _loginS: LoginService,
  ) {

    if (!this._loginS.can(Helper.users.accessList['view-dashboard'])) {
      this._dialog.accessDenied(() => {
        this._loginS.logout();
      });
    }
  }

}
