import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { ApiDeleteResponse, ApiGetResponse, ApiPostResponse, ApiPutResponse, ApprovalEscalationInterface, ApprovalTypeInterface } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class ManageApproversService {

  constructor(

    private _loginS: LoginService,
    private _api: HttpApiService,
  ) { }

  public getApprovalTypes(): Observable<string[]> {
    return this._api.sendGetRequest<HttpResponse<ApiGetResponse<ApprovalTypeInterface>>>(ApiRoutes.approvalSetting, this._loginS.getTokenHeader).pipe(
      map(values => values.body),
      map(values => values?.data),
      map(values => values ? values.map(v => v.Transac_Type) : []),
    );
  }

  public getApprovalLevels(type: "Reimbursement" | "Cash Advance" | "Liquidation" | "Cash Refund" | "Account Payable" | "Account Receivable" | string): Observable<ApprovalEscalationInterface[] | undefined> {
    return this._api.sendGetRequest<HttpResponse<ApiGetResponse<ApprovalTypeInterface>>>(
      ApiRoutes.approvalSetting, this._loginS.getTokenHeader,
    ).pipe(
      map(values => values.body),
      map(valeues => valeues?.data),
      map(valeues => valeues?.find(v => v.Transac_Type == type)?.Approvers),
    );
  }

  public getApprovalEscalationTypeList() {
    return this._api.sendGetRequest<HttpResponse<ApiGetResponse<ApprovalTypeInterface>>>(ApiRoutes.approvalSetting, this._loginS.getTokenHeader).pipe(
      map(values => values.body),
      map(values => values?.data),
    );
  }

  public addApprovalLevel(body: Object) {
    return this._api.sendPostRequest<HttpResponse<ApiPostResponse<ApprovalTypeInterface>>>(
      ApiRoutes.approvalSetting, body, this._loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }

  public updateApprovalLevel(approvalLevelId: number, body: Object) {
    return this._api.sendPutRequest<HttpResponse<ApiPutResponse<ApprovalTypeInterface>>>(
      `${ApiRoutes.approvalSetting}/${approvalLevelId}`, body, this._loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }

  public deleteApprovalLevel(approvalLevelId: number) {
    return this._api.sendDeleteRequest<HttpResponse<ApiDeleteResponse<ApprovalTypeInterface>>>(
      `${ApiRoutes.approvalSetting}/${approvalLevelId}`, this._loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }
}
