import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-acc-message-dialog',
  templateUrl: './acc-message-dialog.component.html',
  styleUrls: ['./acc-message-dialog.component.scss']
})
export class AccMessageDialogComponent {

  private currentTimeOut: any | undefined = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AccMessageDialogInterface,
    public dialogRef: MatDialogRef<AccMessageDialogComponent>,
  ) {
    if (this.data.onOpen) this.data.onOpen(this.dialogRef, this.data);

    this.currentTimeOut = setTimeout(() => {
      this.data.callBack ? this.data.callBack(this.dialogRef, this.data) : null;
    }, this.data.callBackDelay ?? 1000);
  }

  public onClose() {
    if (this.data.onClose) this.data.onClose(this.dialogRef, this.data);

    if (this.currentTimeOut) {
      clearTimeout(this.currentTimeOut);
      this.currentTimeOut = undefined;
      this.data.callBack ? this.data.callBack(this.dialogRef, this.data) : null;
    }

    this.dialogRef.close();
  }

  public get getMessages() {
    const message = this.data.message;
    if (!message) return [];
    return typeof message == "string" ? [message] : message;
  }

}

export interface AccMessageDialogInterface {

  /**
   * Title of dialog.
   */
  title: string,

  /**
   * Actual string message of dialog.
   */
  message: string | undefined | null | string[],

  /**
   * Additional class to the message.
   */
  messageType?: string,

  /**
   * Hide the close button.
   * 
   * User has no way to close the dialog so you must use the `callBack()` function to manually close it.
   */
  disableClodeButton?: boolean,

  /**
   * This `callBack()` will run after `1 second` or defined  on `callBackDelay`.
   * @param dialogRef 
   * @param dataPassed value from data passed in MessageBox
   * @returns 
   */
  callBack?: (dialogRef: MatDialogRef<any>, dataPassed: AccMessageDialogInterface) => void,

  /**
   * Delay of invoking `callBack()` in miliseconds.
   * 
   * Default to `1000 miliseconds`.
   */
  callBackDelay?: number,

  /**
   * Run script when dialog is opened.
   */
  onOpen?: (dialog: MatDialogRef<AccMessageDialogComponent>, dataPassed: AccMessageDialogInterface) => void,

  /**
   * Run script when dialog is closed.
   */
  onClose?: (dialog: MatDialogRef<AccMessageDialogComponent>, dataPassed: AccMessageDialogInterface) => void,
}
