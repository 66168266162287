import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'disbursement', loadChildren: () => import('./disbursement/disbursement.module').then(v => v.DisbursementModule) },
      { path: 'account', loadChildren: () => import('./account-management/account-management.module').then(v => v.AccountManagementModule) },
      { path: 'account-payabale', loadChildren: () => import('./account-payable/account-payable.module').then(v => v.AccountPayableModule) },
      { path: 'account-receivable', loadChildren: () => import('./account-receivable/account-receivable.module').then(v => v.AccountReceivableModule) },
      { path: 'manage-detachments', loadChildren: () => import('./manage-detachments/manage-detachments.module').then(v => v.ManageDetachmentsModule) },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
