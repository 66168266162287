import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, of } from 'rxjs';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(

    private api: HttpApiService,
  ) { }

  public resetPassword(fg?: FormGroup | null, token?: string | null, expired_at?: string | null) {
    if (!fg) return of(undefined);
    if (!token) return of(undefined);
    if (!expired_at) return of(undefined);

    return this.api.sendPostRequest<HttpResponse<any>>(`${ApiRoutes.resetPass}`, {
      Password: fg.value.ConfirmPassword,
      token,
      expired_at,
    }).pipe(
      map(values => values?.body)
    );
  }
}
