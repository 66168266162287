import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { BehaviorSubject, Subject } from "rxjs";

export class Pagination {

  // These are the styles to be applied in pagination
  public readonly activeClass = `flex items-center justify-center px-3 h-8 outline-none text-red-600 border border-red-300 bg-red-50 hover:bg-red-100 hover:text-red-700 hover:border-red-400 disabled:text-red-300 disabled:bg-white disabled:border-red-100 z-[100] transition`;
  public readonly nonActiveClass = `flex items-center justify-center px-3 h-8 outline-none leading-tight text-red-500 bg-white border border-red-100 hover:bg-red-100 hover:text-red-700 hover:border-red-400 disabled:text-red-300 disabled:bg-white disabled:border-red-100 transition`;
  public readonly buttonPrevClass = this.nonActiveClass;
  public readonly buttonNextClass = this.nonActiveClass;

  public readonly attemptToGet$ = new BehaviorSubject(0);
  public readonly reloader$ = new Subject<null | void>();
  public readonly currentPage$ = new BehaviorSubject(1);
  public readonly searchQuery$ = new BehaviorSubject('');
  public readonly pageLimit$ = new BehaviorSubject(5);

  private _start = 0;
  private _totalCount = 0;

  public get totalCount() {
    return this._totalCount;
  }

  public get currentPage() {
    return this.currentPage$.getValue();
  }

  public get query() {
    return this.searchQuery$.getValue();
  }

  public get limit() {
    return this.pageLimit$.getValue();
  }

  public get start() {
    return this.totalCount == 0 ? 0 : Number(this._start);
  }

  public get end() {
    const rawEnd = this._start + this.limit
    return rawEnd > this._totalCount ? this._totalCount : Number(rawEnd);
  }

  public get totalPage() {
    const remaider = this._totalCount % this.limit;

    return Number(((this._totalCount / this.limit) + (remaider == 0 ? 0 : 1)).toFixed(1).split('.')[0]);
  }

  public get hasPrev() {
    return !(this.currentPage > 1);
  }

  public get hasNext() {
    return !(this.currentPage < this.totalPage);
  }

  constructor() {
    this.currentPage$.subscribe(newPage => {
      this._start = (newPage - 1) * this.limit;
    });
  }

  public input = {
    setSearch: (e: Event) => {
      const value = (e.target as HTMLInputElement).value;
      this.search(value);
    },
    setLimit: (e: Event) => {
      const value = Number((e.target as HTMLInputElement).value);
      this.setLimit(value);
    },
    setLimitMat: (e: MatSelectChange) => {
      const value = Number(e.value);
      this.setLimit(value);
    }
  }

  public search(query: string) {
    this.searchQuery$.next(query);
    this.goToPage(1);
  }

  public setLimit(newLimit: number) {
    this.pageLimit$.next(newLimit);
    this.goToPage(1);
  }

  public setTotalCount(total?: number) {
    if (!total) return this;
    this._totalCount = total;
    this.attemptToGet$.next(0);
    return this;
  }

  public goToPage(page: number) {
    if (page < 0) page = 1;
    if (this.currentPage == page) return this;

    this.currentPage$.next(page);
    return this;
  }

  public resetValues() {
    this._start = 0;
    this._totalCount = 0;
  }

  public appendAttemptToGet() {
    this.attemptToGet$.next(this.attemptToGet$.getValue() + 1);
  }

  public attemptReachedLimit() {
    return this.attemptToGet$.getValue() > 5;
  }

}