import { ChangeDetectorRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private readonly reqestCounter$ = new BehaviorSubject(0);
  private readonly debugMode$ = new BehaviorSubject(false);

  constructor() {

    this.reqestCounter$.subscribe(this.onRequestCounterSubscribe);
  }

  private onRequestCounterSubscribe(val: number) {

    // Validate the number of request states.
    if (val < 0) {

      console.warn("Warning: The quantity of finished reqests exceeds the number of requests made. Run `.clear()` to reset value to 0.");
    }
  }

  /**
   * Set debugging mode 
   * 
   * When Debugging mode is `True`, every changes will log the current states. 
   * @param value `True` or `False`
   */
  public setDebugMode(value = true) {
    this.debugMode$.next(value);
  }

  /**
   * Make a new request state.
   */
  public request(changeDetectorRef?: ChangeDetectorRef | null, message?: string | null) {
    this.reqestCounter$.next(this.reqestCounter$.getValue() + 1);

    if (this.debugMode$.getValue()) console.log({ states: this.states });

    if (changeDetectorRef) changeDetectorRef.detectChanges();

    if (message) console.warn("Loader: ", message);
  }

  /**
   * Sets a request to finish state.
   */
  public finish(changeDetectorRef?: ChangeDetectorRef | null, message?: string | null) {
    this.reqestCounter$.next(this.reqestCounter$.getValue() - 1);

    if (this.debugMode$.getValue()) console.log({ states: this.states });

    if (changeDetectorRef) changeDetectorRef.detectChanges();

    if (message) console.log("Loader: ", message);
  }

  /**
   * Check if there is stil request on-going. `False` if there is none.
   */
  public get processing() {

    return this.reqestCounter$.getValue() > 0;
  }

  /**
   * Reset the number of request to 0.
   * @Note Avoid using this as much as possible.
   */
  public clear(changeDetectorRef?: ChangeDetectorRef | null) {
    this.reqestCounter$.next(0);

    if (this.debugMode$.getValue()) console.log({ states: this.states });

    console.warn("Loader is forced to 0. All other request may be affected.");

    if (changeDetectorRef) changeDetectorRef.detectChanges();
  }

  /**
   * Return the number of request states.
   */
  public get states() {
    return this.reqestCounter$.getValue();
  }
}
