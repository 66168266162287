<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="font-semibold whitespace-nowrap text-base">{{cardTitle??''}}</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="whitespace-nowrap">{{cardSubTitle??''}}</div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>