import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, finalize, map, of, Subscription, switchMap, tap } from 'rxjs';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { LiquidationService } from '../../../liquidation-service/liquidation.service';
import { DialogLiquidationNewComponent } from '../liquidation-dialogs/dialog-liquidation-new/dialog-liquidation-new.component';
import { DialogLiquidationToReimbursementComponent } from '../liquidation-dialogs/dialog-liquidation-to-reimbursement/dialog-liquidation-to-reimbursement.component';
import { DialogLiquidationToCashReturnComponent } from '../liquidation-dialogs/dialog-liquidation-to-cash-return/dialog-liquidation-to-cash-return.component';

@Component({
  selector: 'app-liquidation-change-status',
  templateUrl: './liquidation-change-status.component.html',
  styleUrls: ['./liquidation-change-status.component.scss']
})
export class LiquidationChangeStatusComponent implements OnDestroy {

  public readonly waitHandler = new WaitHandler();

  public readonly downloadLink$ = new BehaviorSubject<string | undefined | null>(undefined);
  private readonly _subsGetDownloadLink = this._getSubsGetDownloadLink();

  public get getLiquidationAmount(): number {
    const base = this.data.CA_Request.Amount ?? 0
    const expense = this.data.Total_Cost ?? 0
    return Math.abs(base - expense);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,

    private _matDialogRef: MatDialogRef<LiquidationChangeStatusComponent>,
    private _matDialog: MatDialog,
    private _globalApiS: GlobalApiService,
    private _liquidationS: LiquidationService,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetDownloadLink.unsubscribe();
  }

  public onDisapprove() {
    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => this._liquidationS.updateLiquidationStatus(this.data.ID ?? -1, 'Disapproved').pipe(
        finalize(() => {
          this.waitHandler.finishRequest();
          this.data.reloader$.next();
          this.onClose();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApprove() {
    const validateNextStatus = (): string => {
      const possible = this.data.NextStatus ?? 'No Status';
      return possible == 'For Closing' ? 'Closed' : possible;
    };

    const liquidation: LiquidationModifiedInterface = {
      ...this.data, NextStatus: validateNextStatus()
    };

    const approveToNextStatus = () => {
      const subs: Subscription = of(null).pipe(
        tap(() => this.waitHandler.newRequest()),
        switchMap(() => this._liquidationS.updateLiquidationStatus(liquidation.ID ?? -1, liquidation.NextStatus ?? 'No Status').pipe(
          finalize(() => {
            this.waitHandler.finishRequest();
            this.data.reloader$.next();
            this.onClose();
            subs.unsubscribe();
          })
        )),
      ).subscribe();
    };

    if (!liquidation.Status) {
      this.onClose();
      this._matDialog.open(DialogLiquidationNewComponent, { data: liquidation });
    }
    else if (liquidation.NextStatus == 'For Reimbursement') {
      this.onClose();
      this._matDialog.open(DialogLiquidationToReimbursementComponent, { data: liquidation });
    }
    else if (liquidation.NextStatus == 'For Cash Refund') {
      this.onClose();
      this._matDialog.open(DialogLiquidationToCashReturnComponent, { data: liquidation });
    }
    else approveToNextStatus();
  }

  private _getSubsGetDownloadLink() {
    return of(this.data.Proof).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(proof => proof ? this._globalApiS.getFileUrl(proof, "Receipts").pipe(
        finalize(() => this.waitHandler.finishRequest()),
        map(values => values.body?.url),
        tap(values => this.downloadLink$.next(values)),
      ) : of(null).pipe(finalize(() => this.waitHandler.finishRequest())))
    ).subscribe();
  }

  public onClose() {
    this._matDialogRef.close();
  }

}
