<form [formGroup]="fgNewLiquidation" (submit)="onSubmit()" class="card md:min-w-[500px]">
  <div class="card-header">
    For Liquidation
  </div>
  <div class="card-body">
    <div class="overflow-auto max-h-[250px] p-4 border shadow-sm">
      <div class="grid grid-cols-12 gap-4">

        <div class="col-span-12">
          <div class="alert alert-danger mb-4">
            <div class="font-semibold mb-2"><i class="fa-light fa-brake-warning"></i> Warning</div>
            Once submitted, values cannot be modified or altered. Please verify all fields before submitting.
          </div>
        </div>

        <div class="col-span-12">
          <app-acc-card-collapse cardTitle="CA Details">
            <div *ngIf="waitHandler.processing" class="alert alert-info">
              We are handling your request. Please wait.
            </div>
            <ng-container *ngIf="!waitHandler.processing">
              <div *ngIf="data.CA_Request as request" class="mb-4">
                <div class="mb-4">
                  <div class="font-bold text-lg">CA Details</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Control Number</div>
                  <div *ngIf="request.CntrlNumber" class="ml-2">{{request.CntrlNumber}}</div>
                  <div *ngIf="!request.CntrlNumber" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Amount</div>
                  <div *ngIf="request.Amount" class="ml-2">{{request.Amount|currency:'₱'}}</div>
                  <div *ngIf="!request.Amount" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Dollar Amount</div>
                  <div *ngIf="request.DollarAmount" class="ml-2">{{request.DollarAmount|currency:'$'}}</div>
                  <div *ngIf="!request.DollarAmount" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Charge To</div>
                  <div *ngIf="request.ChargeTo" class="ml-2">{{request.ChargeTo}}</div>
                  <div *ngIf="!request.ChargeTo" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Date Of Use</div>
                  <div *ngIf="request.DateOfUse" class="ml-2">{{request.DateOfUse}}</div>
                  <div *ngIf="!request.DateOfUse" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Description</div>
                  <div *ngIf="request.Description" class="ml-2">{{request.Description}}</div>
                  <div *ngIf="!request.Description" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Due Date</div>
                  <div *ngIf="request.DueDate" class="ml-2">{{request.DueDate}}</div>
                  <div *ngIf="!request.DueDate" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Purpose</div>
                  <div *ngIf="request.Purpose" class="ml-2">{{request.Purpose}}</div>
                  <div *ngIf="!request.Purpose" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Transaction Date</div>
                  <div *ngIf="request.TransactionDate" class="ml-2">{{request.TransactionDate}}</div>
                  <div *ngIf="!request.TransactionDate" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Remarks</div>
                  <div *ngIf="request.Remarks" class="ml-2">{{request.Remarks}}</div>
                  <div *ngIf="!request.Remarks" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Created at</div>
                  <div *ngIf="request.created_at" class="ml-2">{{request.created_at}}</div>
                  <div *ngIf="!request.created_at" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Updated At</div>
                  <div *ngIf="request.updated_at" class="ml-2">{{request.updated_at}}</div>
                  <div *ngIf="!request.updated_at" class="ml-2 text-zinc-500">No data</div>
                </div>
                <div class="mb-4">
                  <div class="font-bold">Updated By</div>
                  <div *ngIf="request.UpdatedBy" class="ml-2">
                    (AGF-{{request.UpdatedBy.ID|padStart:5:'0'}})
                    {{request.UpdatedBy.Lastname}},
                    {{request.UpdatedBy.Firstname}}
                  </div>
                  <div *ngIf="!data.UpdatedBy" class="ml-2 text-zinc-500">No data</div>
                </div>
              </div>
              <div *ngIf="data.History?.length" class="mb-4">
                <div class="border rounded-sm shadow-sm p-4">
                  <div class="font-bold text-lg mb-3">History</div>
                  <div *ngFor="let history of data.History" class="bg-zinc-100 p-2 mb-2 border text-sm">
                    <div class="mb-2">
                      <div class="font-bold">Created by</div>
                      <div *ngIf="history.UpdatedBy" class="ml-2">
                        (AGF-{{history.UpdatedBy.ID|padStart:5:'0'}})
                        {{history.UpdatedBy.LastName}},
                        {{history.UpdatedBy.FirstName}}
                      </div>
                      <div *ngIf="!history.UpdatedBy" class="ml-2 text-zinc-500">No data</div>
                    </div>
                    <div class="mb-2">
                      <div class="font-bold">Status</div>
                      <div *ngIf="history.Status" class="ml-2">{{history.Status}}</div>
                      <div *ngIf="!history.Status" class="ml-2 text-zinc-500">No data</div>
                    </div>
                    <div class="mb-2">
                      <div class="font-bold">Created at</div>
                      <div *ngIf="history.Date" class="ml-2">{{history.Date|formatDateTime}}</div>
                      <div *ngIf="!history.Date" class="ml-2 text-zinc-500">No data</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </app-acc-card-collapse>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <span class="form-label"> Total Expenses <span class="text-red-400">*</span></span>
          <div class="form-group">
            <div class="form-group-label">₱</div>
            <input formControlName="Total_Cost" type="text" (keypress)="Helper.input.positiveNumeric($event)"
              class="form-group-input">
          </div>
          <div *ngIf="fgNewLiquidation.get('Total_Cost')?.invalid && fgNewLiquidation.get('Total_Cost')?.touched"
            class="text-red-500">
            <div *ngIf="fgNewLiquidation.get('Total_Cost')?.errors?.['required']" class="text-sm">
              * Total Expenses is required.
            </div>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <div class="mb-4">
            <span class="form-label">
              Proof
              <span class="text-red-400">* </span>
              <span class="text-muted">(PDF Format)</span>
            </span>
            <div class="form-group">
              <div class="form-group-label">
                <i class="fa-light fa-receipt"></i>
              </div>
              <label class="form-group-input {{fgNewLiquidation.get('Proof')?.value?'':'text-zinc-400'}}"
                for="tab-tr-updload-proof">
                {{fgNewLiquidation.get('Proof')?.value?.name ||'Upload Proof'}}
              </label>
              <input (input)="onUploadProof($event)" type="file" class="hidden" placeholder="Upload Proof" accept=".pdf"
                id="tab-tr-updload-proof">
            </div>
            <div *ngIf="fgNewLiquidation.get('Proof')?.invalid && fgNewLiquidation.get('Proof')?.touched"
              class="text-red-500 text-sm">
              * Proof is required.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="card-footer">
    <div *ngIf="waitHandler.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>

    <div class="form-submit mb-4">
      <button [disabled]="waitHandler.processing" type="submit" class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Save Transaction
      </button>
      <button (click)="onClose()" type="button" class="custom-button">Close</button>
    </div>
  </div>
</form>