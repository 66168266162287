<div class="card md:min-w-[500px]">
  <div class="card-header">Liquidation</div>
  <div class="card-body overflow-auto max-h-[400px]">
    <div *ngIf="waitHandler.processing" class="alert alert-info">
      We are handling your request. Please wait.
    </div>
    <ng-container *ngIf="!waitHandler.processing">
      <div *ngIf="data.Due_Date" class="border shadow mb-4 p-4">
        <div class="mb-4">
          <div class="font-bold text-lg">Liquidation Details</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Transaction Date</div>
          <div *ngIf="data.Transaction_Date" class="ml-2">{{data.Transaction_Date|date}}</div>
          <div *ngIf="!data.Transaction_Date" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Due Date</div>
          <div *ngIf="data.Due_Date" class="ml-2">{{data.Due_Date|date}}</div>
          <div *ngIf="!data.Due_Date" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Total Expenses</div>
          <div *ngIf="data.Total_Cost" class="ml-2">{{data.Total_Cost|currency:'₱'}}</div>
          <div *ngIf="!data.Total_Cost" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div *ngIf="data.NextProcess" class="mb-4">
          <div class="font-bold">{{data.NextProcess}} Amount</div>
          <div *ngIf="getLiquidationAmount" class="ml-2">{{getLiquidationAmount|currency:'₱'}}</div>
          <div *ngIf="!getLiquidationAmount" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Proof <span *ngIf="data.Proof" class="text-sm text-cyan-500">(Click to view)</span>
          </div>
          <div *ngIf="data.Proof" class="ml-2">
            <a class="link" [href]="downloadLink$|async" target="_blank">{{data.Proof}}</a>
          </div>
          <div *ngIf="!data.Proof" class="ml-2 text-zinc-500">No data</div>
        </div>
      </div>
      <div *ngIf="data.CA_Request as request" class="mb-4">
        <div class="mb-4">
          <div class="font-bold text-lg">CA Details</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Control Number</div>
          <div *ngIf="request.CntrlNumber" class="ml-2">{{request.CntrlNumber}}</div>
          <div *ngIf="!request.CntrlNumber" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Amount</div>
          <div *ngIf="request.Amount" class="ml-2">{{request.Amount|currency:'₱'}}</div>
          <div *ngIf="!request.Amount" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Dollar Amount</div>
          <div *ngIf="request.DollarAmount" class="ml-2">{{request.DollarAmount|currency:'$'}}</div>
          <div *ngIf="!request.DollarAmount" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Charge To</div>
          <div *ngIf="request.ChargeTo" class="ml-2">{{request.ChargeTo}}</div>
          <div *ngIf="!request.ChargeTo" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Date Of Use</div>
          <div *ngIf="request.DateOfUse" class="ml-2">{{request.DateOfUse}}</div>
          <div *ngIf="!request.DateOfUse" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Description</div>
          <div *ngIf="request.Description" class="ml-2">{{request.Description}}</div>
          <div *ngIf="!request.Description" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Due Date</div>
          <div *ngIf="request.DueDate" class="ml-2">{{request.DueDate}}</div>
          <div *ngIf="!request.DueDate" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Purpose</div>
          <div *ngIf="request.Purpose" class="ml-2">{{request.Purpose}}</div>
          <div *ngIf="!request.Purpose" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Transaction Date</div>
          <div *ngIf="request.TransactionDate" class="ml-2">{{request.TransactionDate}}</div>
          <div *ngIf="!request.TransactionDate" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Remarks</div>
          <div *ngIf="request.Remarks" class="ml-2">{{request.Remarks}}</div>
          <div *ngIf="!request.Remarks" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Created at</div>
          <div *ngIf="request.created_at" class="ml-2">{{request.created_at}}</div>
          <div *ngIf="!request.created_at" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Updated At</div>
          <div *ngIf="request.updated_at" class="ml-2">{{request.updated_at}}</div>
          <div *ngIf="!request.updated_at" class="ml-2 text-zinc-500">No data</div>
        </div>
        <div class="mb-4">
          <div class="font-bold">Updated By</div>
          <div *ngIf="request.UpdatedBy" class="ml-2">
            (AGF-{{request.UpdatedBy.ID|padStart:5:'0'}})
            {{request.UpdatedBy.Lastname}},
            {{request.UpdatedBy.Firstname}}
          </div>
          <div *ngIf="!data.UpdatedBy" class="ml-2 text-zinc-500">No data</div>
        </div>
      </div>
      <div *ngIf="data.History?.length" class="mb-4">
        <div class="border rounded-sm shadow-sm p-4">
          <div class="font-bold text-lg mb-3">History</div>
          <div *ngFor="let history of data.History" class="bg-zinc-100 p-2 mb-2 border text-sm">
            <div class="mb-2">
              <div class="font-bold">Created by</div>
              <div *ngIf="history.UpdatedBy" class="ml-2">
                (AGF-{{history.UpdatedBy.ID|padStart:5:'0'}})
                {{history.UpdatedBy.LastName}},
                {{history.UpdatedBy.FirstName}}
              </div>
              <div *ngIf="!history.UpdatedBy" class="ml-2 text-zinc-500">No data</div>
            </div>
            <div class="mb-2">
              <div class="font-bold">Status</div>
              <div *ngIf="history.Status" class="ml-2">{{history.Status}}</div>
              <div *ngIf="!history.Status" class="ml-2 text-zinc-500">No data</div>
            </div>
            <div class="mb-2">
              <div class="font-bold">Created at</div>
              <div *ngIf="history.Date" class="ml-2">{{history.Date|formatDateTime}}</div>
              <div *ngIf="!history.Date" class="ml-2 text-zinc-500">No data</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="card-footer">
    <div class="form-submit">
      <button (click)="onClose()" class="custom-button">Close</button>
    </div>
  </div>
</div>