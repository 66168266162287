<div class="card md:min-w-[500px]">
  <div class="card-header"> {{data.title}} </div>
  <div class="card-body">
    <mat-dialog-content>
      <p *ngFor="let msg of getMessages" class="{{data.messageType}}">{{msg}}</p>
    </mat-dialog-content>
  </div>
  <div class="card-footer">
    <div class="flex flex-row-reverse justify-between items-center mb-4">
      <button *ngIf="!data.disableClodeButton" (click)="onClose()" class="custom-button"> Close</button>
    </div>
  </div>
</div>