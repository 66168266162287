<div class="cashier-login-area flex justify-center items-center w-full h-full">
  <form [formGroup]="fgResetPass" (submit)="onSubmit()" class="cashier-login-wrapper">
    <div class="cashier-login-logo text-center mb-12">
      <a [routerLink]="[routes.login]">
        <img src="../../../../assets/img/logo/logo.png" alt="logo not found">
      </a>
      <p class="my-4">
        Reset your password.
      </p>
    </div>

    <div class="mb-4 px-2">
      <span class="form-label">New Password <span class="text-red-500">*</span></span>
      <div class="form-group">
        <input formControlName="NewPassword" (input)="passwordValidator($event)" [type]="newPassShow$|async"
          class="form-group-input" autocomplete>
        <div class="form-group-label">
          <a (click)="toggleNewPassShow()" href="javascript:void(0);">
            <i class="fal {{(newPassShow$|async)=='text'?'fa-eye-slash':'fa-eye'}}"></i>
          </a>
        </div>
      </div>
      <div
        *ngIf="(fgResetPass.get('NewPassword')?.invalid && fgResetPass.get('NewPassword')?.touched)||major.length||problems.length"
        class="text-sm">
        <div *ngIf="fgResetPass.get('NewPassword')?.errors?.['required']" class="text-red-500">
          * New Password is required.
        </div>
        <div *ngFor="let maj of major" class="text-red-500"> * {{maj}} </div>
        <div *ngFor="let prob of problems" class="text-yellow-500"> * {{prob}} </div>
      </div>
    </div>

    <div class="mb-4 px-2">
      <span class="form-label">Confirm New Password <span class="text-red-500">*</span></span>
      <div class="form-group">
        <input formControlName="ConfirmPassword" [type]="confirmPassShow$|async" class="form-group-input" autocomplete>
        <div class="form-group-label">
          <a (click)="toggleConfirmPassShow()" href="javascript:void(0);">
            <i class="fal {{(confirmPassShow$|async)=='text'?'fa-eye-slash':'fa-eye'}}"></i>
          </a>
        </div>
      </div>
      <div *ngIf="fgResetPass.get('ConfirmPassword')?.invalid && fgResetPass.get('ConfirmPassword')?.touched"
        class="text-red-500">
        <div *ngIf="fgResetPass.get('ConfirmPassword')?.errors?.['required']" class="text-sm">
          * Confirm New Password is required.
        </div>
      </div>
    </div>

    <div class="cashier-login-btn-full mb-7">
      <div class="cashier-popup-btn cashier-managesale-top-btn mb-4">
        <button [disabled]="loader.processing" type="submit" mat-raised-button color="primary">Reset</button>
      </div>
      <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>
    </div>
    <div class="cashier-login-footer">
      <div class="cashier-login-footer-account text-center">
        <span class="text-[16px] inline-block text-bodyText">
          Back to <a [routerLink]="[routes.login]" class="text-[16px] underline text-themeRed">Login</a>
        </span>
      </div>
    </div>
  </form>
</div>