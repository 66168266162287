import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-acc-card-collapse',
  templateUrl: './acc-card-collapse.component.html',
  styleUrls: ['./acc-card-collapse.component.scss']
})
export class AccCardCollapseComponent {

  @Input('cardTitle') public cardTitle?: string | null = '';
  @Input('cardSubTitle') public cardSubTitle?: string | null = '';

}
