import { Component } from '@angular/core';

@Component({
  selector: 'app-settingicon',
  templateUrl: './settingicon.component.html',
  styleUrls: ['./settingicon.component.scss']
})
export class SettingiconComponent {

}
