import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { ApiGetResponse, ApiPutResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { ReimbursementInterface, ReimbursementModifiedInterface } from 'src/app/accountancy/utilities/interfaces/reimbursement.interface';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class ReimbursementService {

  constructor(
    private api: HttpApiService,
    private loginS: LoginService,
  ) { }

  public updateStatus(controlNumber: string, newStatus: string) {
    return this.api.sendPutRequest<HttpResponse<ApiPutResponse<ReimbursementInterface>>>(
      `${ApiRoutes.reimbursement}/${controlNumber}/status`,
      { Status: newStatus },
      this.loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }

  public deleteMultipleReimbursement(controllNumbers?: string[] | null) {
    const formBody = {
      CntrlNumbers: controllNumbers,
      DeletedBy: this.loginS.userData?.ID,
    };

    return this.api.sendPostRequest<HttpResponse<any>>(ApiRoutes.reimbursementDeleteMultiple, formBody, this.loginS.getTokenHeader).pipe(
      map(values => values?.body)
    );
  }

  public deleteReibburesement(controllNumber?: string | null) {
    return this.api.sendDeleteRequest<HttpResponse<any>>(
      `${ApiRoutes.reimbursement}/${controllNumber}?DeletedBy=${this.loginS.userData?.ID}`,
      this.loginS.getTokenHeader
    ).pipe(
      map(values => values?.body),
    );
  }

  public updateReimbursement(controllNumber?: string | null, fg?: FormGroup<any>, prompt?: PromptMessage) {
    const formData = fg?.value;
    const formBody = {
      Processor: formData.Processor,
      Company: formData.Company,
      CostCenter: formData.CostCenter,
      Purpose: `${formData.Purpose1}${formData.Purpose2 ? ': ' + formData.Purpose2 : ''}${formData.Purpose3 ? ': ' + formData.Purpose3 : ''}`,
      Product: `${formData.Product1}${formData.Product2 ? ': ' + formData.Product2 : ''}`,
      CoveredDateFrom: Helper.date.format(formData.CoveredDateFrom, 1, '-'),
      CoveredDateTo: Helper.date.format(formData.CoveredDateTo, 1, '-'),
      TransactionDate: Helper.date.format(formData.TransactionDate, 1, '-'),
      DueDate: Helper.date.format(formData.DueDate, 1, '-'),
      ReimbursementAmount: Number(formData.ReimbursementAmount),
      Description: formData.Description,
      InternalOrder: formData.InternalOrder,
      RequestorRemarks: formData.RequestorRemarks,
      Remarks: formData.Remarks,
      UpdatedBy: this.loginS.userData?.ID,
    };

    return this.api.sendPutRequest<HttpResponse<any>>(`${ApiRoutes.reimbursement}/${controllNumber}`, formBody, this.loginS.getTokenHeader).pipe(
      map(values => values?.body),
    );
  }

  public getReimbursementRequests(start?: number | null, limit?: number | null, query?: string | null, by?: number | null, approver?: number | null) {
    return this.api.sendGetRequest<HttpResponse<ApiGetResponse<ReimbursementInterface>>>(
      `${ApiRoutes.reimbursement}?${start != undefined ? 'Start=' + start : ''}${limit ? '&Limit=' + limit : ''}${query ? '&Search=' + query : ''}${by ? '&RequestedBy=' + by : ''}${approver ? '&Approver=' + approver : ''}`,
      this.loginS.getTokenHeader
    );
  }

  public newReimbursement(body: Object) {
    return this.api.sendPostRequest<HttpResponse<any>>(ApiRoutes.reimbursement, body, this.loginS.getTokenHeader).pipe(
      map(values => values?.body),
    );
  }

  public async printSelectedRItems(selectedRItems: ReimbursementModifiedInterface[]) {
    const documentContent: string = await fetch('assets/reports/r.report.html').then(data => data.text());
    const printWindow = window.open('Reimbursement', 'Reimbursement', 'width=500,height=400');
    printWindow?.document.write(`
      <script>
        const data = ${JSON.stringify(selectedRItems)};
      </script>
    `);
    printWindow?.document.write(documentContent);
  }

}
