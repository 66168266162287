<div class="card md:min-w-[500px]">
  <div class="card-header">Cash Advance</div>
  <div class="card-body overflow-auto max-h-[400px]">
    <div *ngIf="waitHandler.processing" class="alert alert-info">
      We are handling your request. Please wait.
    </div>
    <ng-container *ngIf="!waitHandler.processing">
      <div class="mb-4">
        <div class="font-bold">Control Number</div>
        <div *ngIf="data.CntrlNumber" class="ml-2">{{data.CntrlNumber}}</div>
        <div *ngIf="!data.CntrlNumber" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Amount</div>
        <div *ngIf="data.Amount" class="ml-2">{{data.Amount|currency:'₱'}}</div>
        <div *ngIf="!data.Amount" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Dollar Amount</div>
        <div *ngIf="data.DollarAmount" class="ml-2">{{data.DollarAmount|currency:'$'}}</div>
        <div *ngIf="!data.DollarAmount" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Charge To</div>
        <div *ngIf="data.ChargeTo" class="ml-2">{{data.ChargeTo}}</div>
        <div *ngIf="!data.ChargeTo" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Description</div>
        <div *ngIf="data.Description" class="ml-2">{{data.Description}}</div>
        <div *ngIf="!data.Description" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Purpose</div>
        <div *ngIf="data.Purpose" class="ml-2">{{data.Purpose}}</div>
        <div *ngIf="!data.Purpose" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Remarks</div>
        <div *ngIf="data.Remarks" class="ml-2">{{data.Remarks}}</div>
        <div *ngIf="!data.Remarks" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Transaction Date</div>
        <div *ngIf="data.TransactionDate" class="ml-2">{{data.TransactionDate}}</div>
        <div *ngIf="!data.TransactionDate" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Date Of Use</div>
        <div *ngIf="data.DateOfUse" class="ml-2">{{data.DateOfUse}}</div>
        <div *ngIf="!data.DateOfUse" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Due Date</div>
        <div *ngIf="data.DueDate" class="ml-2">{{data.DueDate}}</div>
        <div *ngIf="!data.DueDate" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Created On</div>
        <div *ngIf="data.created_at" class="ml-2">{{data.created_at}}</div>
        <div *ngIf="!data.created_at" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Updated On</div>
        <div *ngIf="data.updated_at" class="ml-2">{{data.updated_at}}</div>
        <div *ngIf="!data.updated_at" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div class="mb-4">
        <div class="font-bold">Updated By</div>
        <div *ngIf="data.UpdatedBy" class="ml-2">
          (AGF-{{data.UpdatedBy.ID|padStart:5:'0'}})
          {{data.UpdatedBy.Lastname}},
          {{data.UpdatedBy.Firstname}}
        </div>
        <div *ngIf="!data.UpdatedBy" class="ml-2 text-zinc-500">No data</div>
      </div>
      <div *ngIf="data.history?.length" class="mb-4">
        <div class="border rounded-sm shadow-sm p-4">
          <div class="font-bold text-lg mb-3">History</div>
          <div *ngFor="let history of data.history" class="bg-zinc-100 p-2 mb-2 border text-sm">
            <div class="mb-2">
              <div class="font-bold">Created by</div>
              <div *ngIf="history.CreatedBy" class="ml-2">
                (AGF-{{history.CreatedBy.ID|padStart:5:'0'}})
                {{history.CreatedBy.LastName}},
                {{history.CreatedBy.FirstName}}
              </div>
              <div *ngIf="!history.CreatedBy" class="ml-2 text-zinc-500">No data</div>
            </div>
            <div class="mb-2">
              <div class="font-bold">Status</div>
              <div *ngIf="history.Status" class="ml-2">{{history.Status}}</div>
              <div *ngIf="!history.Status" class="ml-2 text-zinc-500">No data</div>
            </div>
            <div class="mb-2">
              <div class="font-bold">Type</div>
              <div *ngIf="history.Type" class="ml-2">{{history.Type}}</div>
              <div *ngIf="!history.Type" class="ml-2 text-zinc-500">No data</div>
            </div>
            <div class="mb-2">
              <div class="font-bold">Created at</div>
              <div *ngIf="history.created_at" class="ml-2">{{history.created_at|formatDateTime}}</div>
              <div *ngIf="!history.created_at" class="ml-2 text-zinc-500">No data</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="card-footer">
    <div class="flex justify-between gap-4">
      <button [disabled]="waitHandler.processing" (click)="onClose()" class="custom-button">
        Close
      </button>
      <div *ngIf="data.NextStatus" class="flex gap-4">
        <button [disabled]="waitHandler.processing" (click)="onDisapprove()"
          class="custom-button custom-button-primary">
          <i class="fal fa-xmark"></i> Disapprove
        </button>
        <button [disabled]="waitHandler.processing" (click)="onApprove()"
          class="custom-button custom-button-primary-filled">
          <i class="fal fa-pencil"></i> {{data.NextStatus}}
        </button>
      </div>
    </div>
  </div>
</div>