import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';
import { ReimbursementModifiedInterface } from 'src/app/accountancy/utilities/interfaces/reimbursement.interface';
import { GlobalApiService } from '../../../../../../utilities/services/global-api/global-api.service';
import { ReimbursementService } from '../../../reimbursement-service/reimbursement.service';

@Component({
  selector: 'app-reimbursement-change-status',
  templateUrl: './reimbursement-change-status.component.html',
  styleUrls: ['./reimbursement-change-status.component.scss']
})
export class ReimbursementChangeStatusComponent {

  public readonly waitHandler = new WaitHandler();

  public readonly downloadLink$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReimbursementModifiedInterface,

    private _matDialogRef: MatDialogRef<ReimbursementChangeStatusComponent>,
    private _globalApiS: GlobalApiService,
    private _reimbursementS: ReimbursementService,
  ) {

    if (this.data.Proof) {
      const subs: Subscription = of(null).pipe(
        tap(() => this.waitHandler.newRequest()),
        switchMap(() => this._globalApiS.getFileUrl(data.Proof, 'Receipts').pipe(
          finalize(() => {
            this.waitHandler.finishRequest();
            subs.unsubscribe();
          }),
          tap(values => this.downloadLink$.next(values.body?.url)),
        ))
      ).subscribe();
    }
  }

  public onDisapprove() {
    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => this._reimbursementS.updateStatus(this.data.CntrlNumber ?? '', 'Disapproved').pipe(
        finalize(() => {
          this.waitHandler.finishRequest();
          this.data.reloader$.next();
          this.onClose();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApprove() {
    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => this._reimbursementS.updateStatus(this.data.CntrlNumber ?? '', this.data.NextStatus ?? 'No Status').pipe(
        finalize(() => {
          this.waitHandler.finishRequest();
          this.data.reloader$.next();
          this.onClose();
          subs.unsubscribe();
        })
      )),
    ).subscribe();
  }

  public onClose() {
    this._matDialogRef.close();
  }

}
