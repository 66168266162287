import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { catchError, finalize, map, of, tap } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { CashAdvanceInterface, CashAdvanceModifiedInterface } from 'src/app/accountancy/utilities/interfaces/cash-advance.interface';
import { ApiGetResponse, ApiPostResponse, ApiPutResponse, CurrencyConvertionInterface, DepartmentInterface, DetachmentInterface } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { CommonService } from 'src/app/accountancy/utilities/services/common/common.service';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class CashAdvanceService {

  @ViewChild('tableContent') public tableContent!: ElementRef;

  constructor(
    private api: HttpApiService,
    private loginS: LoginService,
  ) { }

  public updateStatus(controlNumber: string, newStatus: string) {
    return this.api.sendPutRequest<HttpResponse<ApiPutResponse<CashAdvanceInterface>>>(
      `${ApiRoutes.cashAdvance}/${controlNumber}/status`,
      { Status: newStatus },
      this.loginS.getTokenHeader
    ).pipe(
      map(values => values.body),
    );
  }

  public newCashAdvanceRequest(body: Object) {
    return this.api.sendPostRequest<HttpResponse<ApiPostResponse<CashAdvanceInterface>>>(ApiRoutes.cashAdvance, body, this.loginS.getTokenHeader).pipe(
      map(values => values?.body),
    );
  }

  public updateCashAdvanceRequest(controllNumber?: string | null, formGroup?: FormGroup<any>, prompt?: PromptMessage) {
    const formData = formGroup?.value;
    const formBody = {
      Purpose: `${formData.Purpose1}${formData.Purpose2 ? ': ' + formData.Purpose2 : ''}${formData.Purpose3 ? ': ' + formData.Purpose3 : ''}`,
      DateOfUse: Helper.date.format(formData.DateOfUse, 1, '-'),
      TransactionDate: Helper.date.format(formData.TransactionDate, 1, '-'),
      DueDate: Helper.date.format(formData.DueDate, 1, '-'),
      Amount: Number(formData.Amount),
      Description: formData.Description,
      ChargeTo: `${formData.ChargeTo1}${formData.ChargeTo2 ? ': ' + formData.ChargeTo2 : ''}${formData.ChargeTo3 ? ': ' + formData.ChargeTo3 : ''}`,
      Remarks: formData.Remarks,
      Proof: formData.Proof.name,
      UpdatedBy: this.loginS.userData?.ID,
    }

    return this.api.sendPutRequest<HttpResponse<ApiPostResponse<CashAdvanceInterface>>>(`${ApiRoutes.cashAdvance}/${controllNumber}`, formBody, this.loginS.getTokenHeader).pipe(
      map(values => values?.body),
    );
  }

  public deleteCashAdvanceRequest(controllNumber?: string | null, prompt?: PromptMessage | null) {
    return this.api.sendDeleteRequest<HttpResponse<ApiPostResponse<CashAdvanceInterface>>>(
      `${ApiRoutes.cashAdvance}/${controllNumber}?DeletedBy=${this.loginS.userData?.ID}`,
      this.loginS.getTokenHeader
    ).pipe(
      map(values => values?.body),
    );
  }

  public deleteMultipleCARequests(controllNumbers: string[]) {
    return this.api.sendPostRequest<HttpResponse<ApiPostResponse<CashAdvanceInterface>>>(
      ApiRoutes.cashAdvanceDeleteMultiple,
      {
        CntrlNumbers: controllNumbers,
        DeletedBy: this.loginS.userData?.ID,
      }
      ,
      this.loginS.getTokenHeader
    ).pipe(map(values => values?.body));
  }

  public getAllCashAdvanceRequest(start?: number | null, limit?: number | null, query?: string | null, by?: number | null, approver?: number | null) {
    return this.api.sendGetRequest<HttpResponse<ApiGetResponse<CashAdvanceInterface>>>(
      `${ApiRoutes.cashAdvance}?${start != undefined ? 'Start=' + start : ''}${limit ? '&Limit=' + limit : ''}${query ? '&Search=' + query : ''}${by ? '&RequestedBy=' + by : ''}${approver ? '&Approver=' + approver : ''}`,
      this.loginS.getTokenHeader
    );
  }

  public async printSelectedCAs(selectedCAs: CashAdvanceModifiedInterface[] | CashAdvanceInterface[]) {
    const documentContent: string = await fetch('assets/reports/ca.report.html').then(data => data.text());
    const printWindow = window.open('Cash Advance', 'Cash Advance', 'width=500,height=400');
    printWindow?.document.write(`
      <script>
        const data = ${JSON.stringify(selectedCAs)};
      </script>
    `);
    printWindow?.document.write(documentContent);
  }

}


