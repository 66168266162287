<app-acc-table [pagination]="pagination" [showBulkPrint]="true" (bulkPrint)="dialogs.openPrint($event)"
  tableTitle="Liquidation List" [showTitle]="true">
  <table class="custom-table">
    <thead class="text-xs uppercase">
      <tr *ngIf="loader.processing"></tr>
      <tr *ngIf="!loader.processing" class="relaive">
        <th>Control Number</th>
        <th>Status</th>
        <th>Transaction Date</th>
        <th>Peso Amount </th>
        <th>Dollar Amount </th>
        <th>Expense Amount </th>
        <th>Next Process</th>
        <th class="print-data-false">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loader.processing">
        <td colspan="999">
          <app-acc-loader></app-acc-loader>
        </td>
      </tr>
      <ng-container *ngIf="!loader.processing">
        <ng-container *ngIf="liquidationList$|async as liquidationList">
          <tr *ngIf="!liquidationList.length">
            <td colspan="999" class="bg-white sticky left-0">
              <div class="flex gap-2">
                No Data found...
                <div *ngIf="pagination.query" class="text-slate-500">
                  Can't find what your looking for?
                  <a (click)="dialogs.openSearch()" href="javascript:void(0);" class="text-blue-400 underline">
                    Search tip
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of liquidationList">
            <td> {{item.CA_Request.CntrlNumber||''}} </td>
            <td>
              <div class="pill pill-{{transformStatus|method:item.Status}} text-center">
                {{item.Status||'No Status'}}
              </div>
            </td>
            <td>{{item.CA_Request.TransactionDate|date}}</td>
            <td>{{item.CA_Request.Amount|currency:'₱'}}</td>
            <td>{{item.CA_Request.DollarAmount|currency:'$'}}</td>
            <td>{{item.Total_Cost|currency:'₱'}}</td>
            <td>
              <div class="whitespace-nowrap">{{item.NextProcess}}</div>
            </td>
            <td class="flex-centered print-data-false">
              <button (click)="dialogs.openViewMore(item)" class="custom-button-sm">
                <i class="fal fa-eye"></i> Review
              </button>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</app-acc-table>