import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageApiService {

  public string = {
    get: (key: string) => localStorage.getItem(key),
    set: (key: string, value: string) => (localStorage.setItem(key, value)),
  }

  public number = {
    get: (key: string) => <number | null>Number(localStorage.getItem(key)),
    set: (key: string, value: number) => (localStorage.setItem(key, String(value))),
  }

  public object = {
    get: <T>(key: string) => {
      const raw = localStorage.getItem(key);
      return raw ? <T>JSON.parse(raw) : null;
    },
    set: (key: string, value: object) => (localStorage.setItem(key, JSON.stringify(value))),
  }

  public array = {
    get: <T>(key: string) => {
      const raw = localStorage.getItem(key);
      return raw ? <T[]>JSON.parse(raw) : null;
    },
    set: (key: string, value: any[]) => (localStorage.setItem(key, JSON.stringify(value))),
  }

  public remove = (key: string) => localStorage.removeItem(key);

  constructor() { }
}
