<div class="cashier-dashboard-area">
  <div class="cashier-dashboard-sidebar" [class.sidebar-enable]="menuSidebarActive$|async">
    <div class="cashier-menu-wrapper bg-white border-r border-b border-solid border-grayBorder">

      <app-menu></app-menu>

    </div>
    <div class="cashier-menu-overlay cashier-menu-overlay-dashboard" (click)="toggleSideBar()"
      [class.sidebar-enable]="menuSidebarActive$|async"></div>
  </div>
  <div class="cashier-dashboard-main" [class.sidebar-enable]="menuSidebarActive$|async">
    <div class="cashier-header-area">
      <div
        class="cashier-header-wrapper custom-height-70 px-7 custom-height-70 bg-white border-b border-solid border-grayBorder">
        <div class="grid grid-cols-12 items-center h-full">
          <div class="col-span-12">
            <!-- header area start here -->
            <div class="cashier-header-content flex items-center justify-between custom-height-70">

              <div class="cashier-header-bar-responsive cursor-pointer mr-5" (click)="toggleSideBar()">
                <i class="fal fa-bars text-[21px]"></i>
              </div>

              <div class="cashier-header-bar-responsive cursor-pointer mr-5">
                <div class="text-[21px] font-semibold"> Finance Management System</div>
              </div>

              <div class="flex items-center">
                <div
                  class="cashier-header-notify-wrapper px-5 flex items-center border-l border-solid border-grayBorder custom-height-70 pr-0">
                  <div class="cashier-header-language flex items-center relative" (click)="langdropdown()">
                    <div class="cashier-header-language-content">
                      <ul>
                        <li class="flex ">
                          <a href="javascript:void(0)" class="text-[14px] text-bodyText translate-y-[2px] font-bold">
                            <app-settingicon></app-settingicon>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="cashier-quick-dropdown cashier-quick-lang-dropdown"
                      [class.langmenu-enable]="langShortcutActive">
                      <app-settingdropdown [userData]="loginS.userData"></app-settingdropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cashier-header-overlay" (click)="shortmenu()" [class.shortmenu-enable]="menuShortcutActive">
            </div>
            <div class="cashier-header-overlay" (click)="notifydropdown()"
              [class.notifydropdown-enable]="notifyShortcutActive"></div>
            <div class="cashier-header-overlay" (click)="emaildropdown()"
              [class.emaildropdown-enable]="emailShortcutActive"></div>
            <div class="cashier-header-overlay" (click)="langdropdown()" [class.langmenu-enable]="langShortcutActive">
            </div>
            <div class="cashier-header-overlay" (click)="prodropdown()" [class.promenu-enable]="proShortcutActive">
            </div>
            <!-- header area end here -->
          </div>
        </div>
      </div>
    </div>
    <div class="cashier-content-area px-7 mb-10">

      <div *ngIf="(loadingMainPage$|async)" class="flex justify-center items-center h-[calc(100vh/1.5)]">
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      </div>

      <ng-container *ngIf="(loadingMainPage$|async)===false">
        <router-outlet></router-outlet>
      </ng-container>

    </div>

    <div class="my-10 opacity-0">display-none</div>
  </div>
</div>