import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-acc-stepper-step',
  templateUrl: './acc-stepper-step.component.html',
  styleUrls: ['./acc-stepper-step.component.scss']
})
export class AccStepperStepComponent {

  @Input('stepNumber') public stepNumber: string = '';
  @Input('stepTitle') public stepTitle: string = '';
  @Input('stepDetail') public stepDetail: string = '';

  @Output('click') public click = new EventEmitter<void>();

  public onClick() {
    this.click.emit();
  }

}
