<div class="py-6 px-7 maxLg:px-5 maxLg:py-6">
  <div class="cashier-header-profile relative pl-5 flex flex-wrap items-center maxMd:pr-0 mb-9">
    <div class="cashier-header-profile-img w-12 maxSm:mr-0 md:mr-0 cursor-pointer">
      <a [routerLink]="[routes.userProfile, userData?.FormattedID]" class="rounded-[50%] overflow-hidden block">
        <img src="../../../../assets/img/user/male2.png" class="object-cover" alt="profile not found">
      </a>
    </div>
    <div class="cashier-header-profile-info pl-2.5 cursor-pointer">
      <div>
        <a class="text-[15px] font-bold text-heading cursor-pointer"
          [routerLink]="[routes.userProfile, userData?.FormattedID]">
          {{(userData?.Username || 'user name')|titlecase}}
        </a>
      </div>
      <span class="text-[13px] font-normal text-bodyText cursor-pointer">
        <a [routerLink]="[routes.userProfile, userData?.FormattedID]">
          AGF-{{(((userData?.ID|stringify) || 'user id')|titlecase)|padStart:5:'0'}}
        </a>
      </span>
    </div>
  </div>
  <div class="cashier-menu px-0.5">
    <div class="sidenav sidenav-collapsed" [ngClass]="(collapsed$|async) ? 'sidenav-collapsed': ''">
      <div class="scrollwrap scrollwrap_delayed">
        <ul class="sidenav-nav">
          <li class="sidenav-nav-item" *ngFor="let data of sideMenuBars$|async">
            <a class="sidenav-nav-link" (click)="handleClick(data)" *ngIf="data.items && data.items.length > 0"
              [ngClass]="getActiveClass(data)">
              <i class="sidenav-link-icon" [class]="data.icon"></i>
              <span class="sidenav-link-text" @fadeInOut>
                {{data.label}}
              </span>
              <i *ngIf="data.items" class="menu-collapse-icon"
                [ngClass]="!data.expanded ? 'fal fa-angle-right' : 'fal fa-angle-down'"></i>
            </a>
            <!-- start main item on child -->
            <a class="sidenav-nav-link" *ngIf="!data.items || (data.items && data.items.length === 0)"
              [routerLink]="[data.routeLink]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              (click)="shrinkItems(data)">
              <i class="sidenav-link-icon" [class]="data.icon"></i>
              <span class="sidenav-link-text" @fadeInOut>
                {{data.label}}
              </span>
            </a>
            <!-- end main item on child -->
            <div *ngIf="data.items && data.items.length > 0">
              <app-sublevel-menu *ngIf="data.items && data.items.length > 0" [data]="data"
                [collapsed]="(collapsed$|async)" [multiple]="(multiple$|async)"
                [expanded]="data.expanded"></app-sublevel-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="cashier-logo text-center h-[200px] w-full flex flex-col justify-center items-center bg-[#fcf6f6] rounded-lg mt-[60px]">
    <a [routerLink]="['/dashboard']" class="inline-block"><img src="../../../../assets/img/logo/logo.png"
        alt="logo not found"></a>
  </div>

</div>

<app-copyright></app-copyright>