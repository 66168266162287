import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { Error400Component } from './error400/error400.component';
import { Error500Component } from './error500/error500.component';
import { OtpComponent } from './otp/otp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccMaterialsModule } from '../utilities/modules/acc-materials/acc-materials.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


@NgModule({
  declarations: [
    LoginComponent,
    Error400Component,
    Error500Component,
    OtpComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,

    FormsModule,
    ReactiveFormsModule,
    
    AccMaterialsModule,
  ]
})
export class AuthenticationModule { }
