<div class="flex justify-between items-center mb-4">
  <div class="text-xl font-semibold">New Cash Advance Transaction</div>
</div>

<form [formGroup]="fgTR" (submit)="onSubmit()" class="grid grid-cols-12 gap-4">

  <div class="col-span-12">
    <div class="alert alert-danger mb-4">
      <div class="font-semibold mb-2"><i class="fa-light fa-brake-warning"></i> Warning</div>
      Once submitted, values cannot be modified or altered. Please verify all fields before submitting.
    </div>
  </div>

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">Purpose <span class="text-red-400">*</span></span>
      <input formControlName="Purpose" type="text" class="form-control">
      <div *ngIf="fgTR.get('Purpose')?.invalid && fgTR.get('Purpose')?.touched" class="text-red-500 text-sm">
        * Purpose is required.
      </div>
    </div>
  </div>

  <div class="col-span-12 ">
    <div class="mb-4">
      <span class="form-label">CA Amount <span class="text-red-400">*</span></span>
      <div class="form-group">
        <div class="form-group-label">₱</div>
        <input formControlName="Amount" type="text" class="form-group-input" placeholder="0.00">
      </div>
      <div *ngIf="fgTR.get('Amount')?.invalid && fgTR.get('Amount')?.touched" class="text-red-500">
        * Amount is required.
      </div>
      <div *ngIf="fgTR.get('Amount')?.errors?.['numeric']" class="text-red-500 text-sm">
        * Invalid amount value. Please remove any non-numeric characters.
      </div>
    </div>
  </div>

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">CA Description <span class="text-red-400">*</span></span>
      <textarea formControlName="Description" class="form-control"></textarea>
      <div *ngIf="fgTR.get('Description')?.invalid && fgTR.get('Description')?.touched" class="text-red-500 text-sm">
        * Description is required.
      </div>
    </div>
  </div>

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">Charge To <span class="text-red-400">*</span></span>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-4">
          <input formControlName="ChargeTo1" placeholder="Select department" type="text"
            [matAutocomplete]="auto_ChargeTo1" class="form-control">
          <mat-autocomplete #auto_ChargeTo1>
            <mat-option *ngIf="loader.processing" disabled="">Searching...</mat-option>
            <ng-container *ngIf="departmentList$|async as departmentList">
              <mat-option *ngIf="!departmentList.length" disabled="">Nothing found...</mat-option>
              <mat-option *ngFor="let department of departmentList" [value]="department.Name">
                {{department.Name}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <mat-select formControlName="ChargeTo2" class="form-control">
            <mat-option value="No Detachment">No Detachment</mat-option>
            <mat-option value="With Detachment">With Detachment</mat-option>
          </mat-select>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <ng-container *ngIf="fcChargeTo2isDetachment$|async">
            <input formControlName="ChargeTo3" placeholder="Select detachment" type="text"
              [matAutocomplete]="auto_ChargeTo3" class="form-control">
            <mat-autocomplete #auto_ChargeTo3>
              <mat-option *ngIf="loader.processing" disabled="">Searching...</mat-option>
              <ng-container *ngIf="detachmentList$|async as detachmentList">
                <mat-option *ngIf="!detachmentList.length" disabled="">Nothing found...</mat-option>
                <mat-option *ngFor="let detachment of detachmentList" [value]="detachment.Detachment">
                  {{detachment.Detachment}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </ng-container>
        </div>
      </div>
      <div *ngIf="getChargeToIsInvalid" class="text-red-500">
        <div *ngIf="fgTR.get('ChargeTo1')?.errors?.['required']" class="text-sm">
          * Charge to department is required.
        </div>
        <div *ngIf="fgTR.get('ChargeTo1')?.errors?.['existIn']" class="text-sm">
          * Charge to department does not exist.
        </div>
        <div *ngIf="fgTR.get('ChargeTo3')?.errors?.['required']" class="text-sm">
          * Charge to detachment is required.
        </div>
        <div *ngIf="fgTR.get('ChargeTo3')?.errors?.['existIn']" class="text-sm">
          * Charge to detachment does not exist.
        </div>
      </div>
    </div>
  </div>

  <div class="col-span-12">
    <div class="mb-4">
      <span class="form-label">Remarks </span>
      <textarea formControlName="Remarks" class="form-control"></textarea>
    </div>
  </div>

  <div class="col-span-12">
    <div *ngIf="loader.processing" class="alert alert-info mb-4">
      We are handling your request. Please wait.
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>
  </div>

  <div class="col-span-12">
    <div class="form-submit mb-4">
      <button [disabled]="loader.processing" type="submit" class="custom-button custom-button-primary-filled">
        <i class="fal fa-plus-circle"></i> Save Transaction
      </button>
    </div>
  </div>

</form>