import { Component } from '@angular/core';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';

@Component({
  selector: 'app-disbursement-navigator',
  templateUrl: './disbursement-navigator.component.html',
  styleUrls: ['./disbursement-navigator.component.scss']
})
export class DisbursementNavigatorComponent {
  public readonly routes = Routes;

}
