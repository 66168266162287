import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { ApiGetResponse, ApiPutResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { LiquidationInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class LiquidationService {


  constructor(

    private _loginS: LoginService,
    private _api: HttpApiService,
  ) { }

  public toCashReturn(liquidationId: number, body: Object) {
    console.log({
      liquidationId,
      body
    });

    return this._api.sendPutRequest<HttpResponse<ApiPutResponse<LiquidationInterface>>>(`${ApiRoutes.liquidationUpdate}/${liquidationId}/status`, {
      Status: "For Cash Refund",
      CashReturnInfo: body,
    }, this._loginS.getTokenHeader).pipe(map(values => values.body));
  }

  public toReimbursement(liquidationId: number, body: Object) {
    return this._api.sendPutRequest<HttpResponse<ApiPutResponse<LiquidationInterface>>>(`${ApiRoutes.liquidationUpdate}/${liquidationId}/status`, {
      Status: "For Reimbursement",
      ReimbursementInfo: body,
    }, this._loginS.getTokenHeader).pipe(map(values => values.body));
  }

  public updateLiquidationStatus(liquidationId: number, nextStatus: string) {
    return this._api.sendPutRequest<HttpResponse<ApiPutResponse<LiquidationInterface>>>(`${ApiRoutes.liquidationUpdate}/${liquidationId}/status`, {
      Status: nextStatus,
    }, this._loginS.getTokenHeader).pipe(map(values => values.body));
  }

  public getForLiquidation(start?: number | null, limit?: number | null, query?: string | null, by?: number | null, approver?: number | null) {
    return this._api.sendGetRequest<HttpResponse<ApiGetResponse<LiquidationInterface>>>(
      `${ApiRoutes.liquidation}?${start != undefined ? 'Start=' + start : ''}${limit ? '&Limit=' + limit : ''}${query ? '&Search=' + query : ''}${by ? '&RequestedBy=' + by : ''}${approver ? '&Approver=' + approver : ''}`,
      this._loginS.getTokenHeader
    );
  }

  public fileLiquidation(liquidationId: number, body: Object) {
    return this._api.sendPutRequest<HttpResponse<ApiPutResponse<LiquidationInterface>>>(`${ApiRoutes.liquidationUpdate}/${liquidationId}`, {
      ...body
    }, this._loginS.getTokenHeader).pipe(map(values => values.body));
  }

  public updateCashReturnStatus(cashReturnId: number, nextStatus: string) {
    return this._api.sendPutRequest<HttpResponse<ApiPutResponse<LiquidationInterface>>>(`${ApiRoutes.cashReturnUpdate}/${cashReturnId}/status`, {
      Status: nextStatus,
    }, this._loginS.getTokenHeader).pipe(map(values => values.body));
  }

  public getForCashReturn(start?: number | null, limit?: number | null, query?: string | null, by?: number | null, approver?: number | null) {
    return this._api.sendGetRequest<HttpResponse<ApiGetResponse<LiquidationInterface>>>(
      `${ApiRoutes.cashReturn}?${start != undefined ? 'Start=' + start : ''}${limit ? '&Limit=' + limit : ''}${query ? '&Search=' + query : ''}${by ? '&RequestedBy=' + by : ''}${approver ? '&Approver=' + approver : ''}`,
      this._loginS.getTokenHeader
    );
  }

}
