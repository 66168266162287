import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-acc-confirmation',
  templateUrl: './acc-confirmation.component.html',
  styleUrls: ['./acc-confirmation.component.scss']
})
export class AccConfirmationComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AccConfirmationInterface,

    private _matDialogRef: MatDialogRef<AccConfirmationComponent>,
  ) { }

  public onClose() {
    this._matDialogRef.close();
  }

  public onAnswer(yes: boolean) {
    this.data.onAnswer(yes, this._matDialogRef);
  }

  public get getMessages() {
    const message = this.data.message;
    if (!message) return [];
    return typeof message == "string" ? [message] : message;
  }

}

export interface AccConfirmationInterface {
  confirmText?: string | null | undefined,
  cancelText?: string | null | undefined,
  message: string | string[],
  title: string,
  messageType?: string,
  disableClodeButton?: boolean,
  onAnswer: (yes: boolean, matDialogRef: MatDialogRef<AccConfirmationComponent>) => void,
}