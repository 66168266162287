export class ApiRoutes {

  public static readonly void: `javascript:void(0);`;

  public static readonly user = `/api/v1/user`;
  public static readonly users = `/api/v1/users`;
  public static readonly loginUser = `/api/v1/login`;
  public static readonly forgotPass = `/api/v1/forgot-password`;
  public static readonly resetPass = `/api/v1/reset-password`;

  public static readonly departments = `/departments`;
  public static readonly detachments = `/detachments-list`;

  public static readonly cashAdvance = `/api/v1/cash-advance`;
  public static readonly cashAdvanceDeleteMultiple = `/api/v1/cash-advance/control-numbers/delete-multiple`;

  public static readonly disbursed = `/api/v1/disbursements`;

  public static readonly reimbursement = `/api/v1/reimbursement`;
  public static readonly reimbursementDeleteMultiple = `/api/v1/reimbursement/control-numbers/delete-multiple`;

  public static readonly liquidation = `/api/v1/liquidations`;
  public static readonly liquidationUpdate = `/api/v1/liquidation`;

  public static readonly cashReturn = `/api/v1/cash-returns`;
  public static readonly cashReturnUpdate = `/api/v1/cash-return`;

  public static readonly accountPayable = `/api/v1/account-payables`;
  public static readonly accountPayableUpdate = `/api/v1/account-payable`;

  public static readonly accountPayableAssets = `/api/v1/ap-assets`;
  public static readonly accountPayableAssetsUpdate = `/api/v1/ap-asset`;
  public static readonly accountPayableAssetsNoPaymentDetails = `/api/v1/ap-assets/no-payment-details`;

  public static readonly accountPayableCategories = `/api/v1/categories/account-payables`;
  public static readonly accountPayableUpdateCategories = `/api/v1/category/account-payable`;

  public static readonly accountReceivable = `/api/v1/account-receivables`;
  public static readonly accountReceivableUpdate = `/api/v1/account-receivable`;

  public static readonly accountReceivableCategories = `/api/v1/categories/account-receivables`;
  public static readonly accountReceivableUpdateCategories = `/api/v1/category/account-receivable`;

  public static readonly approvalSetting = `/api/v1/approval-settings`;

  public static readonly audits = `/api/v1/general-logs`;

  public static readonly salaryBuiltDates = `/payslips/batch-date`;
  public static readonly salaryDetachmentByDate = `/api/v1/billing-statement`;

  public static readonly accDetachments = `/api/v1/detachments`;

  public static readonly uploadFile = `/get/presigned/upload`;

  public static readonly curencyConverter = `https://api.frankfurter.app/latest`;
  // API is from
  // https://www.frankfurter.app/docs/

  private constructor() { }
}