import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public reRoute(routes: string[]) {
    this.router.navigate(routes);
  }

  constructor(
    private router: Router,
  ) { }
}
