<div class="card md:min-w-[500px]">
  <div class="card-header">
    Search tips
  </div>
  <div class="card-body">
    <ul class="max-w-md space-y-1 list-disc list-inside">
      <li>
        If your searching for
        <span class="font-bold">Dates</span>, use this format
        <span class="font-bold">YYYY-MM-DD</span>.
      </li>
    </ul>

  </div>
  <div class="card-footer">
    <div class="flex flex-row-reverse">
      <button (click)="onClose()" class="custom-button">
        Close
      </button>
    </div>
  </div>
</div>