import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisbursementRoutingModule } from './disbursement-routing.module';
import { CashAdvanceComponent } from './cash-advance/cash-advance.component';
import { LiquidationComponent } from './liquidation/liquidation.component';
import { CustomComponentsModule } from '../../custom-components/custom-components.module';
import { DisbursementComponent } from './disbursement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccMaterialsModule } from '../../utilities/modules/acc-materials/acc-materials.module';
import { AccCustomPipeModule } from '../../utilities/modules/acc-custom-pipe/acc-custom-pipe.module';
import { ReimbursementComponent } from './reimbursement/reimbursement.component';
import { TabCaNewTransactionComponent } from './cash-advance/tabs/tab-ca-new-transaction/tab-ca-new-transaction.component';
import { TabCaStatusComponent } from './cash-advance/tabs/tab-ca-status/tab-ca-status.component';
import { TabCaAllRequestComponent } from './cash-advance/tabs/tab-ca-all-request/tab-ca-all-request.component';
import { CaViewDetailsComponent } from './cash-advance/tabs/tab-ca-all-request/ca-view-details/ca-view-details.component';
import { TabReimbursementAllRequestComponent } from './reimbursement/tabs/tab-reimbursement-all-request/tab-reimbursement-all-request.component';
import { TabReimbursementNewTransactionComponent } from './reimbursement/tabs/tab-reimbursement-new-transaction/tab-reimbursement-new-transaction.component';
import { TabReimbursementStatusComponent } from './reimbursement/tabs/tab-reimbursement-status/tab-reimbursement-status.component';
import { ReimbursementViewDetailsComponent } from './reimbursement/tabs/tab-reimbursement-all-request/reimbursement-view-details/reimbursement-view-details.component';
import { TabForLiquidationComponent } from './liquidation/tabs/tab-for-liquidation/tab-for-liquidation.component';
import { TabForCashReturnComponent } from './liquidation/tabs/tab-for-cash-return/tab-for-cash-return.component';
import { DialogLiquidationToReimbursementComponent } from './liquidation/tabs/tab-for-liquidation/liquidation-dialogs/dialog-liquidation-to-reimbursement/dialog-liquidation-to-reimbursement.component';
import { DialogLiquidationToCashReturnComponent } from './liquidation/tabs/tab-for-liquidation/liquidation-dialogs/dialog-liquidation-to-cash-return/dialog-liquidation-to-cash-return.component';
import { ViewDetailsCaRequestLiquidationComponent } from './liquidation/view-details-ca-request-liquidation/view-details-ca-request-liquidation.component';
import { DialogLiquidationNewComponent } from './liquidation/tabs/tab-for-liquidation/liquidation-dialogs/dialog-liquidation-new/dialog-liquidation-new.component';
import { DisbursementNavigatorComponent } from './disbursement-navigator/disbursement-navigator.component';
import { CaChangeStatusComponent } from './cash-advance/tabs/tab-ca-status/ca-change-status/ca-change-status.component';
import { LiquidationChangeStatusComponent } from './liquidation/tabs/tab-for-liquidation/liquidation-change-status/liquidation-change-status.component';
import { CashReturnChangeStatusComponent } from './liquidation/tabs/tab-for-cash-return/cash-return-change-status/cash-return-change-status.component';
import { ReimbursementChangeStatusComponent } from './reimbursement/tabs/tab-reimbursement-status/reimbursement-change-status/reimbursement-change-status.component';


@NgModule({
  declarations: [
    CashAdvanceComponent,
    LiquidationComponent,
    DisbursementComponent,
    ReimbursementComponent,
    TabCaNewTransactionComponent,
    TabCaStatusComponent,
    TabCaAllRequestComponent,
    CaViewDetailsComponent,
    TabReimbursementAllRequestComponent,
    TabReimbursementNewTransactionComponent,
    TabReimbursementStatusComponent,
    ReimbursementViewDetailsComponent,
    TabForLiquidationComponent,
    TabForCashReturnComponent,
    DialogLiquidationToReimbursementComponent,
    DialogLiquidationToCashReturnComponent,
    ViewDetailsCaRequestLiquidationComponent,
    DialogLiquidationNewComponent,
    DisbursementNavigatorComponent,
    CaChangeStatusComponent,
    LiquidationChangeStatusComponent,
    CashReturnChangeStatusComponent,
    ReimbursementChangeStatusComponent,
  ],
  imports: [
    CommonModule,
    DisbursementRoutingModule,
    CustomComponentsModule,

    FormsModule,
    ReactiveFormsModule,
    AccMaterialsModule,
    AccCustomPipeModule
  ],
  exports: [
    TabCaNewTransactionComponent,
    TabReimbursementNewTransactionComponent,
  ]
})
export class DisbursementModule { }
