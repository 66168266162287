import { Component } from '@angular/core';

@Component({
  selector: 'app-acc-stepper',
  templateUrl: './acc-stepper.component.html',
  styleUrls: ['./acc-stepper.component.scss']
})
export class AccStepperComponent {

  

}
