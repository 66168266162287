import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { LiquidationService } from '../../../../liquidation-service/liquidation.service';
import { FormGroup } from '@angular/forms';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { PromptMessage } from 'src/app/accountancy/utilities/classes/promp-message.class';
import { finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';

@Component({
  selector: 'app-dialog-liquidation-to-cash-return',
  templateUrl: './dialog-liquidation-to-cash-return.component.html',
  styleUrls: ['./dialog-liquidation-to-cash-return.component.scss']
})
export class DialogLiquidationToCashReturnComponent {

  public readonly Helper = Helper;

  public readonly waitHandler = new WaitHandler();
  public readonly prompt = new PromptMessage();

  public get getCashRefundAmount() { return this.data.CA_Request.Amount - (this.data.Total_Cost ?? 0); };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,

    private _dialogRef: MatDialogRef<DialogLiquidationToCashReturnComponent>,
    private _liquidationS: LiquidationService,
  ) { }

  public onSubmit() {
    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => this._liquidationS.toCashReturn(this.data.ID ?? -1, { Amount: this.getCashRefundAmount }).pipe(
        finalize(() => this.waitHandler.finishRequest())
      ))
    ).subscribe(Helper.api.handleStatusResponse({
      prompt: this.prompt,
      successMessage: `Successfully updated to: ${this.data.NextStatus}`,
      onSuccess: () => {
        this._dialogRef.close();
        this.data.reloader$.next();
        subs.unsubscribe();
      }
    }));
  }

  public onClose() {
    this._dialogRef.close();
  }

}
