import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-acc-search-tip',
  templateUrl: './acc-search-tip.component.html',
  styleUrls: ['./acc-search-tip.component.scss']
})
export class AccSearchTipComponent {

  constructor(
    public dialogRef: MatDialogRef<AccSearchTipComponent>,
  ) { }

  public onClose() {
    this.dialogRef.close();
  }

}
