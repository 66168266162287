import { Component } from '@angular/core';
import { Routes } from '../../utilities/classes/routes.class';

@Component({
  selector: 'app-error400',
  templateUrl: './error400.component.html',
  styleUrls: ['./error400.component.scss']
})
export class Error400Component {

  public readonly routes = Routes;

}
