import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, finalize, forkJoin, map, mergeMap, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { AccSearchTipComponent } from 'src/app/accountancy/custom-components/acc-search-tip/acc-search-tip.component';
import { Pagination } from 'src/app/accountancy/utilities/classes/pagination.class';
import { AccMessageDialogComponent, AccMessageDialogInterface } from 'src/app/accountancy/custom-components/acc-message-dialog/acc-message-dialog.component';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { ReimbursementService } from '../../reimbursement-service/reimbursement.service';
import { ReimbursementViewDetailsComponent } from '../tab-reimbursement-all-request/reimbursement-view-details/reimbursement-view-details.component';
import { ReimbursementModifiedInterface } from 'src/app/accountancy/utilities/interfaces/reimbursement.interface';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { ManageApproversService } from 'src/app/accountancy/home/account-management/manage-approvers/manage-approvers-service/manage-approvers.service';
import { AccTableComponent } from 'src/app/accountancy/custom-components/acc-table/acc-table.component';
import { ReimbursementChangeStatusComponent } from './reimbursement-change-status/reimbursement-change-status.component';

@Component({
  selector: 'app-tab-reimbursement-status',
  templateUrl: './tab-reimbursement-status.component.html',
  styleUrls: ['./tab-reimbursement-status.component.scss']
})
export class TabReimbursementStatusComponent implements OnDestroy {

  public readonly pagination = new Pagination();
  public readonly reimbursementList$ = new BehaviorSubject<ReimbursementModifiedInterface[]>([]);

  private readonly _subsGetCaList = this._getSubsGetReimbursementList();

  constructor(
    public loader: LoaderService,

    private _loginS: LoginService,
    private _reimbursementS: ReimbursementService,
    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsGetCaList.unsubscribe();
  }

  public dialogs = {
    openSearch: () => this._matDialog.open(AccSearchTipComponent),
    openViewMore: (reimbursement: ReimbursementModifiedInterface) => this._matDialog.open(ReimbursementChangeStatusComponent, { data: { ...reimbursement, reloader$: this.pagination.reloader$, } as ReimbursementModifiedInterface }),
    openPrint: async (table: AccTableComponent) => await table.printTable(),
  }

  public transformStatus(status: string) {
    if (status == "Done") return 'success';
    if (status == "Disapproved") return 'danger';
    return 'warning';
  }

  private _getSubsGetReimbursementList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.loader.request()),
      switchMap(([query, limit]) => this._reimbursementS.getReimbursementRequests(this.pagination.start, limit, query, null, this._loginS.userData?.ID).pipe(
        finalize(() => this.loader.finish()),
        map(values => values.body),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.reimbursementList$.next(values?.data.map(v => ({ ...v, checked: false } as ReimbursementModifiedInterface)) ?? []);
        }),
      ))
    ).subscribe();
  }

}
