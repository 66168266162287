import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';

@Component({
  selector: 'app-liquidation',
  templateUrl: './liquidation.component.html',
  styleUrls: ['./liquidation.component.scss']
})
export class LiquidationComponent {

  public readonly activeTabClass = 'text-red-600 border-red-600 bg-zinc-100 active';
  public readonly nonActiveTabClass = 'border-transparent hover:text-gray-600 hover:border-gray-300';

  public readonly tabForLiquidation$ = new BehaviorSubject<boolean>(true);
  public readonly tabForCashReturn$ = new BehaviorSubject<boolean>(false);

  public readonly routes = Routes;

  public onChangeTab(tabName: "ForLiquidation" | "ForCashReturn") {
    this.tabForLiquidation$.next(false);
    this.tabForCashReturn$.next(false);

    if (tabName == "ForLiquidation") this.tabForLiquidation$.next(true);
    if (tabName == "ForCashReturn") this.tabForCashReturn$.next(true);
  }

}
