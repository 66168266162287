import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';
import { CashAdvanceModifiedInterface } from 'src/app/accountancy/utilities/interfaces/cash-advance.interface';
import { CashAdvanceService } from '../../../cash-advance-service/cash-advance.service';

@Component({
  selector: 'app-ca-change-status',
  templateUrl: './ca-change-status.component.html',
  styleUrls: ['./ca-change-status.component.scss']
})
export class CaChangeStatusComponent {

  public waitHandler = new WaitHandler();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CashAdvanceModifiedInterface,

    private _matDialogRef: MatDialogRef<CaChangeStatusComponent>,
    private _caS: CashAdvanceService,
  ) { }

  public onClose() {
    this._matDialogRef.close();
  }

  public onDisapprove() {
    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => this._caS.updateStatus(this.data.CntrlNumber ?? '', 'Disapproved').pipe(
        finalize(() => {
          this.waitHandler.finishRequest();
          this.data.reloader$.next();
          this.onClose();
          subs.unsubscribe();
        })
      ))
    ).subscribe();
  }

  public onApprove() {
    const subs: Subscription = of(null).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(() => this._caS.updateStatus(this.data.CntrlNumber ?? '', this.data.NextStatus ?? 'No Status').pipe(
        finalize(() => {
          this.waitHandler.finishRequest();
          this.data.reloader$.next();
          this.onClose();
          subs.unsubscribe();
        })
      )),
    ).subscribe();
  }

}
