import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { CashAdvanceModifiedInterface } from 'src/app/accountancy/utilities/interfaces/cash-advance.interface';
import { ReimbursementModifiedInterface } from 'src/app/accountancy/utilities/interfaces/reimbursement.interface';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Component({
  selector: 'app-reimbursement-view-details',
  templateUrl: './reimbursement-view-details.component.html',
  styleUrls: ['./reimbursement-view-details.component.scss']
})
export class ReimbursementViewDetailsComponent {

  public readonly downloadLink$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    public loader: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: ReimbursementModifiedInterface,

    private _globalApiS: GlobalApiService,
    private _matDialogRef: MatDialogRef<ReimbursementViewDetailsComponent>,
  ) {

    if (this.data.Proof) {
      const subs: Subscription = of(null).pipe(
        tap(() => loader.request()),
        switchMap(() => this._globalApiS.getFileUrl(data.Proof, 'Receipts').pipe(
          finalize(() => loader.finish()),
          tap(values => this.downloadLink$.next(values.body?.url)),
        ))
      ).subscribe();
    }
  }

  public onClose() {
    this._matDialogRef.close();
  }

}
