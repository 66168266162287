import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoaderService } from '../../utilities/services/loader/loader.service';
import { PromptMessage } from '../../utilities/classes/promp-message.class';
import { Routes } from '../../utilities/classes/routes.class';
import { Helper } from '../../utilities/classes/helper.class';
import { BehaviorSubject, finalize } from 'rxjs';
import { OtpService } from './otp-service/otp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccMessageDialogComponent, AccMessageDialogInterface } from '../../custom-components/acc-message-dialog/acc-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtpComponent {

  public routes = Routes;

  public readonly prompt = new PromptMessage();

  private readonly _token$ = new BehaviorSubject<string | null | undefined>(undefined);
  private readonly _exp$ = new BehaviorSubject<string | null | undefined>(undefined);

  public readonly newPassShow$ = new BehaviorSubject<'text' | 'password'>('password');
  public readonly confirmPassShow$ = new BehaviorSubject<'text' | 'password'>('password');

  public readonly problems: string[] = [];
  public readonly major: string[] = [];

  public fgResetPass = this.fb.group({
    NewPassword: ['', [Validators.required]],
    ConfirmPassword: ['', [Validators.required]],
  });

  constructor(
    public loader: LoaderService,

    private dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private otpService: OtpService,
  ) {

    this._getSearchParams();
  }

  public onSubmit() {
    const formData = this.fgResetPass.value;

    if (Helper.getInvalidControls(this.fgResetPass).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, 'alert alert-danger mb-4', 6000);
      return;
    }

    if (formData.ConfirmPassword != formData.NewPassword) {
      this.prompt.set(`* Password did not matched.`, 'alert alert-danger mb-4', 6000);
      return;
    }

    this._validatePassword(formData.NewPassword);
    if (this.major.length) {
      this.prompt.set(`* Check for password suggetions.`, 'alert alert-danger mb-4', 6000);
      return;
    }

    this.prompt.clear();
    this.otpService.resetPassword(this.fgResetPass, this._token$.getValue(), this._exp$.getValue()).pipe(
      finalize(() => this.loader.finish(this.cdr))
    ).subscribe({
      next: (data) => {
        const passedData: AccMessageDialogInterface = {
          title: 'Password successfully reset!',
          message: 'Please re-login using your new password.',
          onClose: (dialog, data) => {

            this.router.navigate([this.routes.login]);
          }
        };
        this.dialog.open(AccMessageDialogComponent, {
          data: passedData,
        });
      },
      error: (err) => {
        Helper.handleError(err, this.prompt);
      }
    })
  }

  public toggleNewPassShow() {
    this.newPassShow$.next(this.newPassShow$.getValue() == "text" ? "password" : "text");
  }

  public toggleConfirmPassShow() {
    this.confirmPassShow$.next(this.confirmPassShow$.getValue() == "text" ? "password" : "text");
  }

  public passwordValidator(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    this._validatePassword(value);
  }

  private _getSearchParams() {

    // Get the token from the route parameters
    this.activeRoute.paramMap.subscribe(params => {
      this._token$.next(params.get('token'));
    });

    // Get the exp from the query parameters
    this.activeRoute.queryParamMap.subscribe(queryParams => {
      this._exp$.next(queryParams.get('exp'));
    });
  }

  private _validatePassword(value?: string | null) {
    if (!value) return;

    const problems: string[] = [];
    const major: string[] = [];

    if (value.length < 8) major.push('Must be 8 characters long.');
    if (!Helper.string.hasCommonCharacter(value, Helper.string.aplhapbet.upperCase)) major.push('Must have upper case.');
    if (!Helper.string.hasCommonCharacter(value, Helper.string.aplhapbet.numbers)) major.push('Must have numerical.');
    if (value.length && this._commonWords.filter(v => value.includes(v)).length) major.push('Password contains common weak words.');

    if (value.length && this._weakWords.filter(v => value.includes(v)).length) problems.push('Password contains weak words. (You can ignore this suggestion)');


    this.problems.splice(0, this.problems.length);
    this.major.splice(0, this.major.length);

    problems.forEach(v => this.problems.push(v));
    major.forEach(v => this.major.push(v));
  }

  private readonly _commonWords = ["123", "qwerty"];
  private readonly _weakWords = ["asd", "zxc", "asdfgh", "zxcvbn", "help", "password", "love", "you"];

}
