import { UserAccessInterface } from "../interfaces/user-data.interface";

export interface UserAccessItemInterface {
  module: string,
  id: string,
  actions: UserAccessActionInterface[],
}

export interface UserAccessActionInterface {
  class?: string | null,
  name: string,
  id: string,
}

export class UserAccess {

  public static readonly modules: UserAccessItemInterface[] = [
    {
      id: "m1",
      module: "Menu",
      actions: [
        { id: "m1-1", name: "Dashboard", class: "mb-4" },

        { id: "m1-2", name: "Disbursement" },
        { id: "m1-3", name: "Disbursement>All transaction" },
        { id: "m1-4", name: "Disbursement>Cash advance" },
        { id: "m1-5", name: "Disbursement>Reimbursement" },
        { id: "m1-6", name: "Disbursement>Liquidation" },
        { id: "m1-7", name: "Disbursement>Manage Approver", class: "mb-4" },

        { id: "m1-8", name: "PRS", class: "mb-4" },

        { id: "m1-9", name: "Account Management" },
        { id: "m1-10", name: "Account Management>My profile" },
        { id: "m1-11", name: "Account Management>User list" },
        { id: "m1-12", name: "Account Management>User Access" },
        { id: "m1-13", name: "Account Management>Audit logs" },
      ]
    },
    {
      id: "m2",
      module: "Dashboard",
      actions: [
        { id: "m2-1", name: "view-dashboard" },

      ]
    },
    {
      id: "m3",
      module: "Disbursement",
      actions: [
        { id: "m3-1", name: "view-tab-option-request-CA" },
        { id: "m3-2", name: "view-tab-option-CA-status" },
        { id: "m3-3", name: "view-tab-option-my-CA-requests" },
        { id: "m3-4", name: "view-tab-option-all-CA-requests", class: "mb-4" },

        { id: "m3-5", name: "view-transaction-requests-in-cash-advance" },
        { id: "m3-6", name: "add-transaction-requests-in-cash-advance" },
        { id: "m3-7", name: "update-transaction-requests-in-cash-advance", class: "mb-4" },

        { id: "m3-8", name: "view-tab-option-Request-reimbursement" },
        { id: "m3-9", name: "view-tab-option-reimbursement-status" },
        { id: "m3-10", name: "view-tab-option-my-reimbursement-requests" },
        { id: "m3-11", name: "view-tab-option-all-reimbursement-requests", class: "mb-4" },

        { id: "m3-12", name: "view-transaction-requests-in-reimbursement" },
        { id: "m3-13", name: "add-transaction-requests-in-reimbursement" },
        { id: "m3-14", name: "update-transaction-requests-in-reimbursement", class: "mb-4" },

        { id: "m3-15", name: "view-tab-option-Request-liquidation" },
        { id: "m3-16", name: "view-tab-option-liquidation-status" },
        { id: "m3-17", name: "view-tab-option-my-liquidation-requests" },
        { id: "m3-18", name: "view-tab-option-all-liquidation-requests", class: "mb-4" },

        { id: "m3-19", name: "view-transaction-requests-in-liquidation" },
        { id: "m3-20", name: "add-transaction-requests-in-liquidation" },
        { id: "m3-21", name: "update-transaction-requests-in-liquidation", class: "mb-4" },
      ]
    },
    {
      id: "m4",
      module: "PRS",
      actions: [

      ]
    },
    {
      id: "m5",
      module: "Account Management",
      actions: [
        { id: "m5-1", name: "view-in-my-profile" },
        { id: "m5-2", name: "update-in-my-profile", class: "mb-4" },

        { id: "m5-3", name: "view-in-other-profile" },
        { id: "m5-4", name: "update-in-other-profile", class: "mb-4" },

        { id: "m5-5", name: "view-tab-option-User-list-in-user-list" },
        { id: "m5-6", name: "view-tab-option-New-user-in-user-list" },

        { id: "m5-7", name: "view-in-user-list" },
        { id: "m5-8", name: "add-in-user-list" },
        { id: "m5-9", name: "update-in-user-list" },
        { id: "m5-10", name: "delete-in-user-list", class: "mb-4" },

        { id: "m5-11", name: "view-in-user-access" },
        { id: "m5-12", name: "update-in-user-access" },

        { id: "m5-13", name: "view-in-audit-logs" },
      ]
    },
  ];

  public static readonly accessList = {
    "Dashboard": "m1-1",
    "Disbursement": "m1-2",
    "Disbursement>All transaction": "m1-3",
    "Disbursement>Cash advance": "m1-4",
    "Disbursement>Reimbursement": "m1-5",
    "Disbursement>Liquidation": "m1-6",
    "Disbursement>Manage Approver": "m1-7",
    "PRS": "m1-8",
    "Account Management": "m1-9",
    "Account Management>My profile": "m1-10",
    "Account Management>User list": "m1-11",
    "Account Management>User Access": "m1-12",
    "Account Management>Audit logs": "m1-13",
    "view-dashboard": "m2-1",
    "view-in-all-transaction": "m3-1",
    "update-in-all-transaction": "m3-2",
    "delete-in-all-transaction": "m3-3",
    "view-tab-option-Transaction-requests-in-cash-advance": "m3-4",
    "view-tab-option-CA-status-in-cash-advance": "m3-5",
    "view-tab-option-CA-history-in-cash-advance": "m3-6",
    "view-transaction-requests-in-cash-advance": "m3-7",
    "add-transaction-requests-in-cash-advance": "m3-8",
    "update-transaction-requests-in-cash-advance": "m3-9",
    "delete-transaction-requests-in-cash-advance": "m3-10",
    "view-tab-option-Transaction-requests-in-reimbursement": "m3-11",
    "view-tab-option-CA-status-in-reimbursement": "m3-12",
    "view-tab-option-CA-history-in-reimbursement": "m3-13",
    "view-transaction-requests-in-reimbursement": "m3-14",
    "add-transaction-requests-in-reimbursement": "m3-15",
    "update-transaction-requests-in-reimbursement": "m3-16",
    "delete-transaction-requests-in-reimbursement": "m3-17",
    "view-tab-option-Transaction-requests-in-liquidation": "m3-18",
    "view-tab-option-CA-status-in-liquidation": "m3-19",
    "view-tab-option-CA-history-in-liquidation": "m3-20",
    "view-transaction-requests-in-liquidation": "m3-21",
    "add-transaction-requests-in-liquidation": "m3-22",
    "update-transaction-requests-in-liquidation": "m3-23",
    "delete-transaction-requests-in-liquidation": "m3-24",
    "view-in-my-profile": "m5-1",
    "update-in-my-profile": "m5-2",
    "view-in-other-profile": "m5-3",
    "update-in-other-profile": "m5-4",
    "view-tab-option-User-list-in-user-list": "m5-5",
    "view-tab-option-New-user-in-user-list": "m5-6",
    "view-in-user-list": "m5-7",
    "add-in-user-list": "m5-8",
    "update-in-user-list": "m5-9",
    "delete-in-user-list": "m5-10",
    "view-in-user-access": "m5-11",
    "update-in-user-access": "m5-12",
    "view-in-audit-logs": "m5-13",
  }

  public static readonly userCan = (moduleName: string, userAccessList: UserAccessInterface[]): boolean => {
    const [a, c] = moduleName.replace("m", "").split("-").map(v => Number(v));
    const userAccesses = userAccessList[a - 1]?.Access;
    const value = userAccesses ? userAccesses[c - 1] : false;
    return !!value;
  }
}
