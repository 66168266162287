import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { Error400Component } from './error400/error400.component';
import { Error500Component } from './error500/error500.component';
import { OtpComponent } from './otp/otp.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

const routes: Routes = [
  { path: "", component: LoginComponent },
  {
    path: "auth", children: [
      { path: "login", component: LoginComponent },
      { path: "error/404", component: Error400Component },
      { path: "error/500", component: Error500Component },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "otp/:token", component: OtpComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
