import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, finalize, map, of, switchMap, tap } from 'rxjs';
import { WaitHandler } from 'src/app/accountancy/utilities/classes/wait-handler.class';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Component({
  selector: 'app-view-details-ca-request-liquidation',
  templateUrl: './view-details-ca-request-liquidation.component.html',
  styleUrls: ['./view-details-ca-request-liquidation.component.scss']
})
export class ViewDetailsCaRequestLiquidationComponent implements OnDestroy {

  public readonly waitHandler = new WaitHandler();

  public readonly downloadLink$ = new BehaviorSubject<string | undefined | null>(undefined);
  private readonly _subsGetDownloadLink = this._getSubsGetDownloadLink();

  public get getLiquidationAmount(): number {
    const base = this.data.CA_Request.Amount ?? 0
    const expense = this.data.Total_Cost ?? 0
    return Math.abs(base - expense);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,

    private _globalApiS: GlobalApiService,
    private _matDialogRef: MatDialogRef<ViewDetailsCaRequestLiquidationComponent>,
  ) { }

  ngOnDestroy(): void {
    this._subsGetDownloadLink.unsubscribe();
  }

  private _getSubsGetDownloadLink() {
    return of(this.data.Proof).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(proof => proof ? this._globalApiS.getFileUrl(proof, "Receipts").pipe(
        finalize(() => this.waitHandler.finishRequest()),
        map(values => values.body?.url),
        tap(values => this.downloadLink$.next(values)),
      ) : of(null).pipe(finalize(() => this.waitHandler.finishRequest())))
    ).subscribe();
  }

  public onClose() {
    this._matDialogRef.close();
  }

}
