import { Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './login-service/login.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Routes } from '../../utilities/classes/routes.class';
import { LoaderService } from '../../utilities/services/loader/loader.service';
import { PromptMessage } from '../../utilities/classes/promp-message.class';
import { Helper } from '../../utilities/classes/helper.class';
import { LocalStorageApiService } from '../../utilities/services/local-storage-api/local-storage-api.service';
import { Router } from '@angular/router';
import { PrsModule } from '../../home/prs/prs.module';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {

  public readonly prompt = new PromptMessage();
  public readonly routes = Routes;

  public readonly hide$ = new BehaviorSubject(true);

  public readonly fgLogin = new FormGroup({
    Username: new FormControl('', [Validators.required]),
    Password: new FormControl('', [Validators.required]),
  });

  constructor(
    public loader: LoaderService,

    private router: Router,
    private loginS: LoginService,
  ) {

    this.validateIfUserIsLoggedIn();
  }

  private validateIfUserIsLoggedIn() {
    const { userIsLogedIn } = this.loginS;

    if (userIsLogedIn) this.router.navigate([this.routes.dashboard]);
  }

  public toggleVisisbility() {
    this.hide$.next(!this.hide$.getValue());
  }

  public onSubmitLogin() {
    if (Helper.getInvalidControls(this.fgLogin).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, 'alert alert-danger mb-4', 6000);
      return;
    }

    this.prompt.clear();
    const formData = this.fgLogin.value;
    this.loginS.login(formData.Username, formData.Password, this.prompt);
  }

}