import { Injectable } from '@angular/core';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { SideNavBarInterface } from 'src/app/accountancy/utilities/interfaces/global.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private routes = Routes;

  public sideMenus: SideNavBarInterface[] = [

    {
      routeLink: this.routes.dashboard,
      icon: 'fal fa-home',
      label: 'Dashboard',
      access: Helper.users.accessList.Dashboard,
    },
    {
      routeLink: this.routes.tabName.disburesement,
      icon: 'fal fa-cash-register',
      label: 'Disbursement',
      access: Helper.users.accessList.Disbursement,
      items: [
        {
          routeLink: this.routes.disbursementList,
          label: 'Disbursement List',
          access: Helper.users.accessList['Disbursement>Cash advance'],
        },
        {
          routeLink: this.routes.cashAdvance,
          label: 'Cash Advance',
          access: Helper.users.accessList['Disbursement>Cash advance'],
        },
        {
          routeLink: this.routes.liquidation,
          label: 'Liquidation',
          access: Helper.users.accessList['Disbursement>Liquidation'],
        },
        {
          routeLink: this.routes.reimbursement,
          label: 'Reimbursement',
          access: Helper.users.accessList['Disbursement>Reimbursement']
        },
      ]
    },
    {
      routeLink: this.routes.tabName.prs,
      icon: 'fal fa-sack-dollar',
      label: 'PRS',
      access: Helper.users.accessList.PRS,
    },
    {
      routeLink: this.routes.accountPayable,
      icon: 'fal fa-money-check-alt',
      label: 'Accounts Payable',
      access: Helper.users.accessList.Disbursement,
      items: [
        {
          routeLink: this.routes.accountPayableCategories,
          label: 'AP Categories',
          access: Helper.users.accessList['Disbursement>Cash advance'],
        },
        {
          routeLink: this.routes.accountPayableManage,
          label: 'AP Management',
          access: Helper.users.accessList['Disbursement>Cash advance'],
        },
      ]
    },
    {
      routeLink: this.routes.accountReceivable,
      icon: 'fal fa-envelope-open-dollar',
      label: 'Accounts Receivable',
      access: Helper.users.accessList.Disbursement,
      items: [
        {
          routeLink: this.routes.accountReceivableCategories,
          label: 'AR Categories',
          access: Helper.users.accessList['Disbursement>Cash advance'],
        },
        {
          routeLink: this.routes.accountReceivableManage,
          label: 'AR Management',
          access: Helper.users.accessList['Disbursement>Cash advance'],
        },
      ]
    },
    {
      routeLink: this.routes.manageDetachments,
      icon: 'fal fa-map-marked-alt',
      label: 'Manage Detachments',
      access: Helper.users.accessList.Disbursement
    },
    {
      routeLink: undefined,
      icon: 'fal fa-funnel-dollar',
      label: 'General Ledger',
      access: Helper.users.accessList.Disbursement
    },
    {
      routeLink: undefined,
      icon: 'fal fa-money-check-edit',
      label: 'Tax Accounting',
      access: Helper.users.accessList.Disbursement,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-file-invoice-dollar',
      label: 'Financial Reporting',
      access: Helper.users.accessList.Disbursement,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-comments-alt-dollar',
      label: 'Reconciliation',
      access: Helper.users.accessList.Disbursement,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-hands-usd',
      label: 'Audit & Compliance',
      access: Helper.users.accessList.Disbursement,
    },
    {
      routeLink: undefined,
      icon: 'fal fa-wallet',
      label: 'Budgeting & Forecasting',
      access: Helper.users.accessList.Disbursement,
    },
    {
      routeLink: 'user',
      icon: 'fal fa-user',
      label: 'Account Management',
      access: Helper.users.accessList['Account Management'],
      items: [
        {
          routeLink: this.routes.userProfile,
          label: 'My Profile',
          access: Helper.users.accessList['Account Management>My profile'],
        },
        {
          routeLink: this.routes.userList,
          label: 'User List',
          access: Helper.users.accessList['Account Management>User list'],
        },
        {
          routeLink: this.routes.userAccess,
          label: 'User Access',
          access: Helper.users.accessList['Account Management>User Access'],
        },
        {
          routeLink: this.routes.manageApprover,
          label: 'Manage Approvers',
          access: Helper.users.accessList['Disbursement>Manage Approver'],
        },
        {
          routeLink: this.routes.userAudits,
          label: 'Audit Logs',
          access: Helper.users.accessList['Account Management>Audit logs'],
        }
      ]
    },
  ]

  constructor() { }
}

