import { ChangeDetectorRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface WaitHandlerClass {
  /**
   * Tell WaitHandler to make a new request.
   * @param changeDetector `(Optional)` to detect changes in you component
   */
  newRequest: (changeDetectorRef?: ChangeDetectorRef | null) => void;
  /**
   * Tell WaitHandler that a request has finished.
   * @param changeDetector `(Optional)` to detect changes in you component.
   */
  finishRequest: (changeDetectorRef?: ChangeDetectorRef | null) => void;
  /**
   * Force WaitHandler to set process to `0`.
   * 
   * AVOID USING THIS IN PRODUCTION.
   * @param changeDetector `(Optional)` to detect changes in you component.
   */
  forceClear: (changeDetectorRef?: ChangeDetectorRef | null) => void;
  /**
   * A `BehaviorSubject` that signals if there is a `new request` or a `request finishes`. 
   */
  valueChanges: BehaviorSubject<number>;
  /**
   * Check if there is a on-going request that hasn't finished yet.
   */
  processing: boolean,
  /**
   * Returns the number of request currently on process.
   */
  pendingRequest: number,
}

export class WaitHandler implements WaitHandlerClass {

  private _requestCounter$ = new BehaviorSubject(0);

  public get valueChanges() { return this._requestCounter$; };

  public get processing() { return this._requestCounter$.getValue() > 0 };

  public get pendingRequest() { return this._requestCounter$.getValue(); };

  constructor(debugMode: boolean = false) {

    this._requestCounter$.subscribe(newValue => {
      if (debugMode) console.log(`${newValue} pending requests.`);

      if (newValue < 0) {
        console.warn("WaitHandler Warning: The quantity of finished reqests exceeds the number of requests made. Run `.forceClear()` to reset value to 0.");
      }
    });
  }

  public newRequest(changeDetectorRef?: ChangeDetectorRef | null) {
    this._requestCounter$.next(this._requestCounter$.getValue() + 1);

    if (changeDetectorRef) changeDetectorRef.detectChanges();
  }

  public finishRequest(changeDetectorRef?: ChangeDetectorRef | null) {
    this._requestCounter$.next(this._requestCounter$.getValue() - 1);

    if (changeDetectorRef) changeDetectorRef.detectChanges();
  }

  public forceClear(changeDetectorRef?: ChangeDetectorRef | null) {
    this._requestCounter$.next(0);

    if (changeDetectorRef) changeDetectorRef.detectChanges();
  }

}