import { Component } from '@angular/core';

@Component({
  selector: 'app-editprofileicon',
  templateUrl: './editprofileicon.component.html',
  styleUrls: ['./editprofileicon.component.scss']
})
export class EditprofileiconComponent {

}
