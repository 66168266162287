import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { UserTokenInterface } from 'src/app/accountancy/utilities/interfaces/user-data.interface';

@Component({
  selector: 'app-settingdropdown',
  templateUrl: './settingdropdown.component.html',
  styleUrls: ['./settingdropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingdropdownComponent {

  @Input('userData') public userData: UserTokenInterface | undefined | null;

  public readonly routes = Routes;

  constructor(

    public loginS: LoginService
  ) { }

}
