import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, of } from 'rxjs';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(

    private api: HttpApiService
  ) { }

  public sendEmail(fg?: FormGroup | null) {
    if (!fg) return of(null);

    return this.api.sendPostRequest<HttpResponse<any>>(`${ApiRoutes.forgotPass}`, fg.value).pipe(
      map(values => values?.body),
    );
  }
}
