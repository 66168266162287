import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from '../authentication/login/login-service/login.service';
import { Routes } from '../utilities/classes/routes.class';
import { AccMessageDialogService } from '../custom-components/acc-message-dialog/acc-message-dialog-service/acc-message-dialog.service';
import { AccMessageDialogInterface } from '../custom-components/acc-message-dialog/acc-message-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnDestroy {

  public readonly routes = Routes;

  public readonly menuSidebarActive$ = new BehaviorSubject(false);
  public readonly settingDropdown$ = new BehaviorSubject(false);
  public readonly loadingMainPage$ = new BehaviorSubject(false);

  public toggleSideBar() {
    this.menuSidebarActive$.next(!this.menuSidebarActive$.getValue());
  }

  public toggleSettingDropdown() {
    this.settingDropdown$.next(!this.settingDropdown$.getValue());
  }


  //short menu activation start
  menuShortcutActive: boolean = false;
  shortmenu() {
    if (this.menuShortcutActive == false) {
      this.menuShortcutActive = true;
      this.emailShortcutActive = false;
      this.notifyShortcutActive = false;
      this.langShortcutActive = false;
      this.proShortcutActive = false;
    }
    else {
      this.menuShortcutActive = false;
    }
  }
  //short menu activation end

  //short menu activation start
  notifyShortcutActive: boolean = false;
  notifydropdown() {
    if (this.notifyShortcutActive == false) {
      this.menuShortcutActive = false;
      this.emailShortcutActive = false;
      this.notifyShortcutActive = true;
      this.langShortcutActive = false;
      this.proShortcutActive = false;
    }
    else {
      this.notifyShortcutActive = false;
    }
  }
  //short menu activation end

  //short menu activation start
  emailShortcutActive: boolean = false;
  emaildropdown() {
    if (this.emailShortcutActive == false) {
      this.menuShortcutActive = false;
      this.emailShortcutActive = true;
      this.notifyShortcutActive = false;
      this.langShortcutActive = false;
      this.proShortcutActive = false;
    }
    else {
      this.emailShortcutActive = false;

    }
  }
  //short menu activation end

  //short menu activation start
  langShortcutActive: boolean = false;
  langdropdown() {
    if (this.langShortcutActive == false) {
      this.menuShortcutActive = false;
      this.emailShortcutActive = false;
      this.notifyShortcutActive = false;
      this.langShortcutActive = true;
      this.proShortcutActive = false;
    }
    else {
      this.langShortcutActive = false;

    }
  }
  //short menu activation end

  //short menu activation start
  proShortcutActive: boolean = false;
  prodropdown() {
    if (this.proShortcutActive == false) {
      this.menuShortcutActive = false;
      this.emailShortcutActive = false;
      this.notifyShortcutActive = false;
      this.langShortcutActive = false;
      this.proShortcutActive = true;
    }
    else {
      this.proShortcutActive = false;

    }
  }
  //short menu activation end



  private _handeStartNav(e: NavigationStart) {
    this.loadingMainPage$.next(true);
    this._cdr.detectChanges();
  }

  private _handleEndNav(e: NavigationEnd) {
    if (e.url != this.routes.login) this.loginS.updateSavedUserData();

    this._handleUserSession();
  }

  private _handleUserSession() {

    // get value of userIsLogedIn and loginData for validation
    const { userIsLogedIn, isSessionExpired } = this.loginS;

    // Validate if user is not loged in, return current user to login page
    if (!userIsLogedIn) {
      this.router.navigate([this.routes.login]);
      return;
    }

    // Check if session is expired. If session is expired, logout this user.
    if (isSessionExpired) {
      this.loginS.logout();
      this.loadingMainPage$.next(false)
      return;
    }

    // Add a navigation count to current user. This will limit user's navigation 
    this.loginS.addNavigationCount();

    // Every Nagivation end, hide the menu bar
    this.menuSidebarActive$.next(false);

    setTimeout(() => this.loadingMainPage$.next(false), 500);
  }

  private readonly _subsRouterEvent = this._getSubsRouterEvent();

  constructor(
    public loginS: LoginService,

    private _mDialogS: AccMessageDialogService,
    private _cdr: ChangeDetectorRef,
    private router: Router,
  ) { }

  public ngOnDestroy(): void {
    this._subsRouterEvent.unsubscribe();
  }

  private _getSubsRouterEvent() {
    return this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) this._handeStartNav(e);
      if (e instanceof NavigationEnd) this._handleEndNav(e);
    })
  }

}
