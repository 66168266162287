import { Pipe, PipeTransform } from "@angular/core";
import { Helper } from "../classes/helper.class";

@Pipe({
  name: 'replace',
})
export class ReplaceCustomPipe implements PipeTransform {
  transform(currentString: string, oldString: string, newString: string) {
    return Helper.string.replaceAll(currentString, oldString, newString);
  }
}

@Pipe({
  name: 'formatDate',
})
export class FormatDateCustomPipe implements PipeTransform {
  transform(dateString: string) {
    if (!dateString) return '';
    return new Date(dateString).toDateString();
  }
}

@Pipe({
  name: 'formatTime',
})
export class FormatTimeCustomPipe implements PipeTransform {
  transform(timeString: string) {
    if (!timeString) return '';
    return new Date(`${new Date().toLocaleDateString()} ${timeString}`).toLocaleTimeString();
  }
}

@Pipe({
  name: 'formatDateTime',
})
export class FormatDateTimeCustomPipe implements PipeTransform {
  transform(dateTimeString: string) {
    if (!dateTimeString) return '';
    const date = new Date(dateTimeString);
    return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
  }
}

@Pipe({
  name: 'method',
})
export class MethodCustomPipe implements PipeTransform {
  /**
   * @Description A custom function invoker that accepts only one parameter.
   * @Suggestion Use `function` pipe to accept multiple parameters.
   * @Note Anything outside the function cannot be called.
   */
  transform(func: Function, param?: any, ...moreParam: any) {
    return func(param, moreParam);
  }
}

@Pipe({
  name: 'function',
})
export class FunctionCustomPipe implements PipeTransform {
  /**
   * @Description A custom function invoker that accepts multiple parameter. Parameters are returned as an array.
   * @Note Anything outside the function cannot be called.
   */
  transform(func: Function, ...params: any[]) {
    return func(params);
  }
}

@Pipe({
  name: 'stringify',
})
export class StringifyCustomPipe implements PipeTransform {
  /**
   * @Description run JSON.stringify() and return its value.
   */
  transform(value?: any) {
    if (!value) return '';
    const converted = JSON.stringify(value, null, " ");
    return converted == "{}" ? '' : converted;
  }
}

@Pipe({
  name: 'roundoff',
})
export class RoundoffCustomPipe implements PipeTransform {
  transform(value: number | string | undefined, decimals: number = 2) {
    if (!value) return '0.00';
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }
}

@Pipe({
  name: 'evaluate',
})
export class EvaluateCustomPipe implements PipeTransform {
  transform(value: number | string | undefined) {
    if (!value) return '';
    return String(eval(String(value)));
  }
}

@Pipe({
  name: 'padStart',
})
export class PadStartCustomPipe implements PipeTransform {
  transform(value: string | number | undefined | null, maxLength: number, fillString: string) {
    if (!value) return '';
    return String(value).padStart(maxLength, fillString)
  }
}

@Pipe({
  name: 'padEnd',
})
export class PadEndCustomPipe implements PipeTransform {
  transform(value: string | undefined, maxLength: number, fillString: string) {
    if (!value) return '';
    return String(value).padEnd(maxLength, fillString)
  }
}

@Pipe({
  name: 'arrToStr',
})
export class ArrToStrCustomPipe implements PipeTransform {
  transform(value: any[] | null | undefined) {
    if (!value) return '';
    return Helper.string.replaceAll(value.toString(), ',', ', ');
  }
}

@Pipe({
  name: 'arrMap',
})
export class ArrMapCustomPipe implements PipeTransform {
  transform(value: any[] | null | undefined, key: string) {
    if (!value) return [];
    return value.map(v => v[key]);
  }
}

@Pipe({
  name: 'strLimit',
})
export class StrLimitCustomPipe implements PipeTransform {
  transform(value?: string | null, totalLength?: number | null) {
    if (!value) return '';
    value = String(value);
    const splicedLength = totalLength ? (totalLength > 0 ? totalLength : value.length) : value.length;
    const spliced = value.slice(0, splicedLength - 3);
    return spliced + (spliced.length < value.length ? '...' : '');
  }
}

@Pipe({
  name: 'objToArr',
})
export class ObjToArrCustomPipe implements PipeTransform {
  transform(value?: Object) {
    if (!value) return [];
    const pairs: { key: string, value: any }[] = [];
    Object.entries(value).forEach(([key, value]) => pairs.push({ key, value }));
    return pairs;
  }
}

@Pipe({
  name: 'consoleLog',
})
export class ConsoleLogCustomPipe implements PipeTransform {
  transform(value?: any) {
    console.log({ value });
    return value;
  }
}

@Pipe({
  name: 'uppcaseOn',
})
export class UppcaseOnCustomPipe implements PipeTransform {
  transform(str: string, index?: number | null) {
    if (index == undefined || index == null) return str;
    const char = str[index].toUpperCase();
    return `${str.substring(0, index)}${char}${str.substring(index + 1, str.length)}`;
  }
}

@Pipe({
  name: 'numberPlacement',
})
export class NumberPlacementCustomPipe implements PipeTransform {
  transform(place?: number | null): string {
    if (!place) return '';
    if (place < 1) return `${place}`;
    if (place == 1) return '1st'
    if (place == 2) return '2nd'
    if (place == 3) return '3rd'
    return `${place}th`
  }
}

