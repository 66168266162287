import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { LiquidationModifiedInterface } from 'src/app/accountancy/utilities/interfaces/liquidation.interface';

@Component({
  selector: 'app-dialog-liquidation-to-reimbursement',
  templateUrl: './dialog-liquidation-to-reimbursement.component.html',
  styleUrls: ['./dialog-liquidation-to-reimbursement.component.scss']
})
export class DialogLiquidationToReimbursementComponent {

  public get getInitialAmount() {
    return Number(((this.data.Total_Cost ?? 0) - this.data.CA_Request.Amount).toFixed(2));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LiquidationModifiedInterface,
    public loader: LoaderService,

    private _dialogRef: MatDialogRef<DialogLiquidationToReimbursementComponent>,
  ) { }

  public onClose() {
    this._dialogRef.close();
  }

}
