<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.944" viewBox="0 0 17 16.944">
  <g id="logout_1_" data-name="logout (1)" transform="translate(0 -0.85)">
    <g id="Group_2761" data-name="Group 2761" transform="translate(0 0.85)">
      <g id="Group_2760" data-name="Group 2760">
        <path id="Path_5777" data-name="Path 5777" d="M8.472,16.382H2.118a.706.706,0,0,1-.706-.706V2.968a.706.706,0,0,1,.706-.706H8.472a.706.706,0,0,0,0-1.412H2.118A2.121,2.121,0,0,0,0,2.968V15.676a2.121,2.121,0,0,0,2.118,2.118H8.472a.706.706,0,1,0,0-1.412Z" transform="translate(0 -0.85)" fill="#616161"/>
      </g>
    </g>
    <g id="Group_2763" data-name="Group 2763" transform="translate(5.648 4.38)">
      <g id="Group_2762" data-name="Group 2762">
        <path id="Path_5778" data-name="Path 5778" d="M181.242,111.6l-4.292-4.236a.706.706,0,1,0-.991,1.005l3.067,3.027h-8.22a.706.706,0,0,0,0,1.412h8.22l-3.067,3.027a.706.706,0,1,0,.991,1.005l4.292-4.236a.706.706,0,0,0,0-1.005Z" transform="translate(-170.1 -107.165)" fill="#616161"/>
      </g>
    </g>
  </g>
</svg>