import { Component } from '@angular/core';

@Component({
  selector: 'app-logouticon',
  templateUrl: './logouticon.component.html',
  styleUrls: ['./logouticon.component.scss']
})
export class LogouticonComponent {

}
