import { Component, OnDestroy } from '@angular/core';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { DisbursedService } from './disbursed-service/disbursed.service';
import { Pagination } from '../../utilities/classes/pagination.class';
import { BehaviorSubject, combineLatest, finalize, map, startWith, Subscription, switchMap, tap } from 'rxjs';
import { WaitHandler } from '../../utilities/classes/wait-handler.class';
import { DisbursedInterface } from '../../utilities/interfaces/disbursed.interface';
import { MatDialog } from '@angular/material/dialog';
import { CaViewDetailsComponent } from './cash-advance/tabs/tab-ca-all-request/ca-view-details/ca-view-details.component';
import { ReimbursementViewDetailsComponent } from './reimbursement/tabs/tab-reimbursement-all-request/reimbursement-view-details/reimbursement-view-details.component';

@Component({
  selector: 'app-disbursement',
  templateUrl: './disbursement.component.html',
  styleUrls: ['./disbursement.component.scss']
})
export class DisbursementComponent implements OnDestroy {

  public readonly routes = Routes;

  public readonly waitHandler = new WaitHandler();
  public readonly pagination = new Pagination();
  public readonly disbursedList$ = new BehaviorSubject<DisbursedInterface[]>([]);

  private readonly _subsDisbursedList: Subscription = this._getSubsDisbursedList();

  constructor(
    private _disbursedS: DisbursedService,

    private _matDialog: MatDialog,
  ) { }

  public ngOnDestroy(): void {
    this._subsDisbursedList.unsubscribe();
  }

  public openViewDetails(item: DisbursedInterface) {
    if (item.TransacType == "Cash Advance") this._matDialog.open(CaViewDetailsComponent, { data: item.Transaction });
    if (item.TransacType == "Reimbursement") this._matDialog.open(ReimbursementViewDetailsComponent, { data: item.Transaction });
  }

  private _getSubsDisbursedList() {
    return combineLatest([
      this.pagination.searchQuery$.pipe(startWith(this.pagination.query)),
      this.pagination.pageLimit$,
      this.pagination.currentPage$,
      this.pagination.reloader$.pipe(startWith(null)),
    ]).pipe(
      tap(() => this.waitHandler.newRequest()),
      switchMap(([query, limit]) => this._disbursedS.getDisbursedList(this.pagination.start, limit, query).pipe(
        finalize(() => this.waitHandler.finishRequest()),
        map(values => values.body),
        tap(values => {
          this.pagination.setTotalCount(values?.total_rows);
          this.disbursedList$.next(values?.data ?? []);
        }),
      ))
    ).subscribe();
  }

}
