<div class="cashier-login-area flex justify-center items-center w-full h-full">
  <form [formGroup]="fgLogin" (submit)="onSubmitLogin()" class="cashier-login-wrapper">
    <div class="cashier-login-logo text-center mb-7">
      <img src="../../../../assets/img/logo/logo.png" alt="logo not found">
    </div>
    <div class="mb-7 text-center">
      <div class="text-[21px] font-semibold">Login</div>
      Financial Management And Accounting System
    </div>
    <div class="cashier-select-field mb-5">
      <div class="cashier-input-field-style">
        <mat-form-field class="w-full" [floatLabel]="'always'" [hideRequiredMarker]="true">
          <mat-label class="hidden">Username</mat-label>
          <input formControlName="Username" matInput placeholder="Username">
        </mat-form-field>
        <caption><mat-icon>person_outline</mat-icon></caption>
        <div *ngIf="fgLogin.get('Username')?.invalid && fgLogin.get('Username')?.touched" class="text-danger text-sm">
          * Username is required.
        </div>
      </div>
    </div>
    <div class="cashier-select-field mb-5">
      <div class="cashier-input-field-style cashier-input-field-style-eye">
        <mat-form-field class="w-full" [floatLabel]="'always'" [hideRequiredMarker]="true">
          <mat-label class="hidden">Enter your password</mat-label>
          <input formControlName="Password" matInput placeholder="Password" [type]="(hide$|async) ? 'password' : 'text'"
            [autocomplete]="!(hide$|async)">
          <button mat-icon-button matSuffix (click)="toggleVisisbility()" type="button" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide$|async">
            <mat-icon>{{(hide$|async) ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div *ngIf="fgLogin.get('Password')?.invalid && fgLogin.get('Password')?.touched" class="text-danger text-sm">
          * Password is required.
        </div>
      </div>
    </div>
    <div class="cashier-login-footer-forgot text-end mb-5">
      <a [routerLink]="[routes.forgotPassword]" class="text-[16px] inline-block underline text-themeRed">Forgot
        Password?</a>
    </div>
    <div class="cashier-login-btn mb-7">
      <div class="cashier-login-btn-full cashier-managesale-top-btn">
        <button [disabled]="loader.processing" type="submit" mat-raised-button color="primary" class="disabled:bg-grayBgLight">Log in</button>
      </div>
    </div>
    <div *ngIf="prompt.message" class="{{prompt.type}}">
      {{prompt.message}}
    </div>
  </form>
</div>