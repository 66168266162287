<div class="cashier-login-area flex justify-center items-center w-full h-full">
  <form [formGroup]="fgForgotPass" (submit)="onSubmit()" class="cashier-login-wrapper">
    <div class="cashier-login-logo text-center mb-12">
      <img src="../../../../assets/img/logo/logo.png" alt="logo not found">
      <p class="my-4">
        Enter your email to reset password.
      </p>
    </div>
    <div class="cashier-select-field mb-5">
      <div class="cashier-input-field-style">
        <mat-form-field class="w-full" [floatLabel]="'always'">
          <input formControlName="Email" matInput type="email" [placeholder]="'Email'">
        </mat-form-field>
        <caption><mat-icon>mail_outline</mat-icon></caption>
      </div>
      <div *ngIf="fgForgotPass.get('Email')?.invalid && fgForgotPass.get('Email')?.touched" class="text-red-500">
        <div *ngIf="fgForgotPass.get('Email')?.errors?.['required']" class="text-sm">
          * Email is required
        </div>
        <div *ngIf="fgForgotPass.get('Email')?.errors?.['email']" class="text-sm">
          * Invalid Email
        </div>
      </div>
    </div>
    <div class="cashier-login-btn-full mb-7">
      <div class="cashier-popup-btn cashier-managesale-top-btn mb-4">
        <button [disabled]="loader.processing" type="submit" mat-raised-button color="primary">Send</button>
      </div>
      <div *ngIf="prompt.message" class="{{prompt.type}}">{{prompt.message}}</div>
    </div>
    <div class="cashier-login-footer">
      <div class="cashier-login-footer-account text-center">
        <span class="text-[16px] inline-block text-bodyText">
          Back to <a [routerLink]="[routes.login]" class="text-[16px] underline text-themeRed">Login</a>
        </span>
      </div>
    </div>
  </form>
</div>