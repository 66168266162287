import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { ApiRoutes } from 'src/app/accountancy/utilities/classes/api-routes.class';
import { DisbursedInterface } from 'src/app/accountancy/utilities/interfaces/disbursed.interface';
import { ApiGetResponse } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { HttpApiService } from 'src/app/accountancy/utilities/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class DisbursedService {

  constructor(
    private _api: HttpApiService,
    private _loginS: LoginService,
  ) { }

  public getDisbursedList(start?: number | null, limit?: number | null, query?: string | null, by?: number | null, approver?: number | null) {
    return this._api.sendGetRequest<HttpResponse<ApiGetResponse<DisbursedInterface>>>(
      `${ApiRoutes.disbursed}?${start != undefined ? 'Start=' + start : ''}${limit ? '&Limit=' + limit : ''}${query ? '&Search=' + query : ''}${by ? '&RequestedBy=' + by : ''}${approver ? '&Approver=' + approver : ''}`,
      this._loginS.getTokenHeader
    )
  }
}
