import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, finalize, of, Subscription, switchMap, tap } from 'rxjs';
import { CashAdvanceModifiedInterface } from 'src/app/accountancy/utilities/interfaces/cash-advance.interface';
import { GlobalApiService } from 'src/app/accountancy/utilities/services/global-api/global-api.service';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Component({
  selector: 'app-ca-view-details',
  templateUrl: './ca-view-details.component.html',
  styleUrls: ['./ca-view-details.component.scss']
})
export class CaViewDetailsComponent {

  constructor(
    public loader: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: CashAdvanceModifiedInterface,

    private _matDialogRef: MatDialogRef<CaViewDetailsComponent>,
  ) { }

  public onClose() {
    this._matDialogRef.close();
  }

}
