<form class="card md:min-w-[500px]">
  <div class="card-header">
    <div class="flex justify-between">
      For Reimbursement
      <button (click)="onClose()" type="button" class="custom-button">Close</button>
    </div>
  </div>
  <div class="card-body">
    <div class="overflow-auto max-h-[350px]">
      <app-tab-reimbursement-new-transaction (onSuccessSubmit)="onClose()" [initalAmout]="getInitialAmount"></app-tab-reimbursement-new-transaction>
    </div>
  </div>
  <div class="card-footer"></div>
</form>