<div class="mb-5">
  <h5 class="text-[20px] text-heading font-bold mb-1 leading-none">Disbursement</h5>
  <ul>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.dashboard">Home</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] font-semibold font-normal inline-block mr-2">
      Disbursement
    </li>
  </ul>
</div>

<div class="card mb-4">
  <div class="card-header">Disbursement</div>
  <div class="card-body">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
        <a [routerLink]="[routes.disbursementList]"
          class="custom-button custom-button-primary w-full min-h-[90px] justify-center">
          <i class="text-2xl fal fa-list-ol"></i> Disbursed List
        </a>
      </div>
      <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
        <a [routerLink]="[routes.cashAdvance]"
          class="custom-button custom-button-primary w-full min-h-[90px] justify-center">
          <i class="text-2xl fal fa-money-bill-wave"></i> Cash Advance
        </a>
      </div>
      <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
        <a [routerLink]="[routes.liquidation]"
          class="custom-button custom-button-primary w-full min-h-[90px] justify-center">
          <i class="text-2xl fas fa-coins"></i> Liquidation
        </a>
      </div>
      <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
        <a [routerLink]="[routes.manageApprover]"
          class="custom-button custom-button-primary w-full min-h-[90px] justify-center">
          <i class="text-2xl fal fa-users"></i> Manage Approvers
        </a>
      </div>
    </div>
  </div>
  <div class="card-footer"></div>
</div>