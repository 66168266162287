import { Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';

@Component({
  selector: 'app-cash-advance',
  templateUrl: './cash-advance.component.html',
  styleUrls: ['./cash-advance.component.scss']
})
export class CashAdvanceComponent {

  public routes = Routes;

  public readonly varTR$ = new BehaviorSubject(true);
  public readonly varCAS$ = new BehaviorSubject(false);
  public readonly varCAH$ = new BehaviorSubject(false);
  public readonly varCAbyMe$ = new BehaviorSubject(false);

  public readonly activeTabClass = 'text-red-600 border-red-600 bg-zinc-100 active';
  public readonly nonActiveTabClass = 'border-transparent hover:text-gray-600 hover:border-gray-300';

  constructor(
    public loader: LoaderService,
  ) { }

  public selectTab(tabName: "TR" | "CAS" | "CAH" | "CAbyMe") {
    this.varTR$.next(false);
    this.varCAS$.next(false);
    this.varCAH$.next(false);
    this.varCAbyMe$.next(false);

    if (tabName == "TR") this.varTR$.next(true);
    if (tabName == "CAS") this.varCAS$.next(true);
    if (tabName == "CAH") this.varCAH$.next(true);
    if (tabName == "CAbyMe") this.varCAbyMe$.next(true);
  }

}
