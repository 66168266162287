<div class="mb-4">
  <h5 class="text-[20px] text-heading font-bold mb-1 leading-none">Disbursed List</h5>
  <ul>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.dashboard">Home</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2">
      <a [routerLink]="routes.disbursementNavigator">Disbursement</a>
    </li>
    <li class="text-[14px] text-bodyText font-normal inline-block mr-2 translate-y-0">
      <i class="far fa-chevron-right"></i>
    </li>
    <li class="text-[14px] font-semibold font-normal inline-block mr-2">
      Disbursed List
    </li>
  </ul>
</div>

<div class="white-container">
  <app-acc-table [pagination]="pagination" [showBulkPrint]="true" tableTitle="Disbursed List" [showTitle]="true">
    <table class="custom-table">
      <thead class="text-xs uppercase">
        <tr *ngIf="waitHandler.processing"></tr>
        <tr *ngIf="!waitHandler.processing">
          <th>Disbursement Date</th>
          <th>Transaction Type</th>
          <th class="print-data-false flex-centered">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="waitHandler.processing">
          <td colspan="999">
            <app-acc-loader></app-acc-loader>
          </td>
        </tr>
        <ng-container>
          <tr *ngFor="let item of disbursedList$|async">
            <td>{{item.DisbursementDate|date}}</td>
            <td>{{item.TransacType}}</td>
            <td class="print-data-false flex-centered">
              <button (click)="openViewDetails(item)" class="custom-button-sm">
                <i class="fal fa-eye"></i> View More
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </app-acc-table>
</div>