import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { MenuService } from './menu-service/menu.service';
import { SideNavBarInterface, SideNavToggle } from 'src/app/accountancy/utilities/interfaces/global.interface';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';
import { Routes } from 'src/app/accountancy/utilities/classes/routes.class';
import { Helper } from 'src/app/accountancy/utilities/classes/helper.class';
import { LoaderService } from 'src/app/accountancy/utilities/services/loader/loader.service';
import { UserTokenInterface } from 'src/app/accountancy/utilities/interfaces/user-data.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('350ms',
          style({ opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('350ms',
          style({ opacity: 0 })
        )
      ])
    ]),
    trigger('rotate', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' }),
          ])
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent {

  public userData = this.loginS.userData;

  public readonly routes = Routes;

  public Helper = Helper;

  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  public readonly collapsed$ = new BehaviorSubject(false);
  public readonly multiple$ = new BehaviorSubject(false);


  handleClick(item: SideNavBarInterface): void {
    this.shrinkItems(item);
    item.expanded = !item.expanded;
  }

  getActiveClass(data: SideNavBarInterface): string {
    if (!data.routeLink) return '';
    return this.router.url.includes(data.routeLink) ? 'active' : '';
  }

  shrinkItems(item: SideNavBarInterface): void {
    if (this.multiple$.getValue()) return;

    for (let modelItem of this._menuS.sideMenus) {
      if (item !== modelItem && modelItem.expanded) modelItem.expanded = false;
    }
  }

  public sideMenuBars$: Observable<SideNavBarInterface[]> = of(this._menuS.sideMenus).pipe(
    map(sidebar => {
      const checkAccess = (items: SideNavBarInterface[]): SideNavBarInterface[] => {
        return items.filter(v => this.loginS.can(v.access)).map(v => {
          if (v.label == 'My Profile') v.routeLink = `${this.routes.userProfile}/${this.loginS.userData?.FormattedID}`;
          if (v.items) return { ...v, items: checkAccess(v.items) };
          return v;
        })
      }
      return checkAccess(sidebar);
    }),
  )

  constructor(
    public loader: LoaderService,
    public loginS: LoginService,

    private _menuS: MenuService,
    private router: Router
  ) { }
}