import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Routes } from '../../utilities/classes/routes.class';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordService } from './forgot-password-service/forgot-password.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Helper } from '../../utilities/classes/helper.class';
import { PromptMessage } from '../../utilities/classes/promp-message.class';
import { LoaderService } from '../../utilities/services/loader/loader.service';
import { AccMessageDialogComponent, AccMessageDialogInterface } from '../../custom-components/acc-message-dialog/acc-message-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, finalize } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent {

  public readonly routes = Routes;

  public readonly prompt = new PromptMessage();

  public readonly fgForgotPass = this.fb.group({
    Email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    public loader: LoaderService,

    private router: Router,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private fpService: ForgotPasswordService,
    private dialog: MatDialog,
  ) { }

  onSubmit() {
    if (Helper.getInvalidControls(this.fgForgotPass).length) {
      this.prompt.set(Helper.errorMessage.requiredFields, 'alert alert-danger mb-4');
      return;
    }

    this.prompt.clear();
    this.loader.request(this.cdr);
    this.fpService.sendEmail(this.fgForgotPass).pipe(
      finalize(() => this.loader.finish(this.cdr))
    ).subscribe({
      next: (data) => {
        const passedData: AccMessageDialogInterface = {
          title: 'Email sent!',
          message: 'Please follow instruction sent to your email to reset password.',
          onClose: (dialog, data) => {

            this.router.navigate([this.routes.login]);
          }
        };

        this.dialog.open(AccMessageDialogComponent, {
          data: passedData,
        });
      },
      error: (err) => {
        Helper.handleError(err, this.prompt);
      }
    })
  }

}
