import { Component } from '@angular/core';

@Component({
  selector: 'app-baricon',
  templateUrl: './baricon.component.html',
  styleUrls: ['./baricon.component.scss']
})
export class BariconComponent {

}
