import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccMessageDialogComponent, AccMessageDialogInterface } from '../acc-message-dialog.component';
import { LoginService } from 'src/app/accountancy/authentication/login/login-service/login.service';

@Injectable({
  providedIn: 'root'
})
export class AccMessageDialogService {

  public accessDenied(onClose?: () => void) {
    this.open({
      title: "Access Denied",
      message: [
        "It looks like you do not have access to this feature. ",
        "If you think this is a mistake, try to re-login. ",
        "If problem persist, contact your administrator.",
      ],
      messageType: 'text-red-500',
      onClose,
    }, true);
  }

  public open(passedData: AccMessageDialogInterface, disableClose = false) {
    this._dialog.open(AccMessageDialogComponent, {
      data: { ...passedData, }, disableClose,
    });

    return this._dialog;
  }

  constructor(

    private _dialog: MatDialog,
  ) { }
}
