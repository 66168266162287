import { Component } from '@angular/core';

@Component({
  selector: 'app-acc-loader',
  templateUrl: './acc-loader.component.html',
  styleUrls: ['./acc-loader.component.scss']
})
export class AccLoaderComponent {

}
